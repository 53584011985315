import { CustomParameterChoice, CustomParameterChoiceId, CustomParameterType, CustomParameterTypeId, } from "../ordering";
import { IdMap, RArray } from "../collections";
export class CustomParameterTypeParser {
    constructor() {
        // NOTICE As parameter choice availability can be parametrized we have dependency loop
        // To break it we first parse all custom parameter choices without availability spec
        // and set it in the second pass
        this.deferredAvailabilitySpecSetters = [];
    }
    parse(id, customParameter) {
        const choices = IdMap.fromIterable(new RArray(customParameter.choices).mapOptional(([customParameterChoiceId, parameterChoice]) => this.parseCustomParameterChoice(customParameterChoiceId, parameterChoice)));
        const defaultChoice = customParameter.defaultChoice === null
            ? null
            : choices.ids.rawFind(customParameter.defaultChoice);
        return new CustomParameterType({
            id: new CustomParameterTypeId(id),
            isDivisible: customParameter.divisible,
            choices,
            defaultChoice,
        });
    }
    parseCustomParameterChoice(customParameterChoiceId, parameterChoice) {
        if (parameterChoice.availability === "Hidden") {
            return null;
        }
        const customParameterChoice = new CustomParameterChoice({
            id: new CustomParameterChoiceId(customParameterChoiceId),
        });
        this.deferredAvailabilitySpecSetters.push((availabilitySpecParser) => {
            const availabilitySpec = availabilitySpecParser.parse(parameterChoice.availability, parameterChoice.availableWhen);
            customParameterChoice.setAvailability(availabilitySpec);
        });
        return customParameterChoice;
    }
    setCustomParameterChoiceAvailabilitySpecs(availabilitySpecParser) {
        this.deferredAvailabilitySpecSetters.forEach((deferredSetter) => deferredSetter(availabilitySpecParser));
        this.deferredAvailabilitySpecSetters = [];
    }
}
