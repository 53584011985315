import { RArray, RMutableMap } from "../../collections";
import { makeImmutable } from "../../core";
export class DatesWithHours {
    constructor(params) {
        this.datesWithHours = params.datesWithHours;
        this.totalHoursCount = params.totalHoursCount;
        this.dayDates = params.dayDates;
        makeImmutable(this);
    }
    static create(hours) {
        const datesWithHours = hours
            .reduce((acc, hour) => {
            acc.getOrCreate(hour.fullDate, () => []).push(hour);
            return acc;
        }, RMutableMap.empty())
            .freeze()
            .map((hours) => new RArray(hours));
        const dayDates = datesWithHours.values.map((hoursForDate) => hoursForDate.get(0).startOfDay());
        return new DatesWithHours({
            datesWithHours,
            dayDates,
            totalHoursCount: hours.size,
        });
    }
    hoursForDate(date) {
        var _a;
        return (_a = this.datesWithHours.find(date.fullDate)) !== null && _a !== void 0 ? _a : RArray.empty();
    }
    rawHoursForDate(date) {
        var _a;
        return (_a = this.datesWithHours.rawFind(date)) !== null && _a !== void 0 ? _a : RArray.empty();
    }
    get isEmpty() {
        return this.datesWithHours.isEmpty;
    }
    includes(date) {
        return this.hoursForDate(date).some((hour) => hour.eq(date));
    }
}
