import { ProductInstancePrice } from "./ProductInstancePrice";
export const CrossSellingItemInstancePrice = {
    unitPrice,
    totalPrice,
};
//TODO add selectors
function unitPrice(instance) {
    return ProductInstancePrice.price(ProductInstancePrice.priceSelector(instance.productInstance));
}
function totalPrice(instance) {
    return unitPrice(instance).multiply(instance.quantity);
}
