import I18n from "i18n-js";
import { makeAutoObservable } from "mobx";
import { DEFAULT_APARTMENT_INFO, } from "../../../ordering";
import { toPairs } from "../../../core";
import { ErrorBox, SelectableFieldController, TextFieldController, } from "../../../form";
export class RomanianApartmentInfoController {
    constructor(params) {
        this.apartmentInfo = params.apartmentInfo;
        const onBlur = () => {
            if (params.onBlur !== undefined) {
                params.onBlur();
            }
        };
        this.sector = new TextFieldController({
            initialValue: this.apartmentInfo.data.sector,
            onUpdate: (value) => this.apartmentInfo.setData({ sector: value }),
            onBlur,
        });
        this.houseOrBlock = new SelectableFieldController({
            initialValue: this.apartmentInfo.data.houseOrBlock,
            onUpdate: (value) => this.apartmentInfo.setData({ houseOrBlock: value }),
            onClear: () => this.apartmentInfo.setData({
                houseOrBlock: DEFAULT_APARTMENT_INFO.houseOrBlock,
            }),
            onBlur,
        });
        this.block = new TextFieldController({
            initialValue: this.apartmentInfo.data.block,
            onUpdate: (value) => this.apartmentInfo.setData({ block: value }),
            onBlur,
            errorBox: new ErrorBox({
                domainObjects: [this.apartmentInfo],
                errorMessages: {
                    BlockMissing: I18n.t("_common.form.errors.required"),
                },
            }),
        });
        this.staircase = new TextFieldController({
            initialValue: this.apartmentInfo.data.staircase,
            onUpdate: (value) => this.apartmentInfo.setData({ staircase: value }),
            onBlur,
            errorBox: new ErrorBox({
                domainObjects: [this.apartmentInfo],
                errorMessages: {
                    StaircaseMissing: I18n.t("_common.form.errors.required"),
                },
            }),
        });
        this.floor = new TextFieldController({
            initialValue: this.apartmentInfo.data.floor,
            onUpdate: (value) => this.apartmentInfo.setData({ floor: value }),
            onBlur,
            errorBox: new ErrorBox({
                domainObjects: [this.apartmentInfo],
                errorMessages: {
                    FloorMissing: I18n.t("_common.form.errors.required"),
                },
            }),
        });
        this.aptNumber = new TextFieldController({
            initialValue: this.apartmentInfo.data.aptNumber,
            onUpdate: (value) => this.apartmentInfo.setData({ aptNumber: value }),
            onBlur,
            errorBox: new ErrorBox({
                domainObjects: [this.apartmentInfo],
                errorMessages: {
                    AptNumberMissing: I18n.t("_common.form.errors.required"),
                },
            }),
        });
        this.interphone = new TextFieldController({
            initialValue: this.apartmentInfo.data.interphone,
            onUpdate: (value) => this.apartmentInfo.setData({ interphone: value }),
            onBlur,
        });
        this.additionalInfo = new TextFieldController({
            initialValue: this.apartmentInfo.data.additionalInfo,
            onUpdate: (value) => this.apartmentInfo.setData({ additionalInfo: value }),
            onBlur,
        });
        if (!this.apartmentInfo.isEmpty) {
            this.submit();
        }
        makeAutoObservable(this);
    }
    get type() {
        return this.apartmentInfo.type;
    }
    get fields() {
        return {
            sector: this.sector,
            houseOrBlock: this.houseOrBlock,
            block: this.block,
            staircase: this.staircase,
            floor: this.floor,
            aptNumber: this.aptNumber,
            interphone: this.interphone,
            additionalInfo: this.additionalInfo,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelInvalid = this.apartmentInfo.availability.isAvailable.isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.apartmentInfo;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
    clear() {
        this.sector.clear();
        this.houseOrBlock.clear();
        this.block.clear();
        this.staircase.clear();
        this.floor.clear();
        this.aptNumber.clear();
        this.interphone.clear();
        this.additionalInfo.clear();
    }
}
