import { makeAutoObservable } from "mobx";
import * as PurchaseOrderPice from "../../pricing/PurchaseOrderPrice";
import { Availability } from "../Availability";
import { Money } from "../Money";
import { Packing } from "../Packing";
import { PurchaseOrderValidationPrice } from "./PurchaseOrderValidationPrice";
/**
 * Represents an order from the perspective of a buyer.
 * It contains items added by user
 */
export class PurchaseOrder {
    constructor(params) {
        this.restaurant = params.restaurant;
        this.orderingAvailability = params.orderingAvailability;
        this.origin = params.origin;
        this.payload = params.payload;
        this.orderReceiver = params.orderReceiver;
        this.delivery = params.delivery;
        this.payment = params.payment;
        this.fulfillmentInstructions = params.fulfillmentInstructions;
        this.purchaseOrderFulfillmentTime = params.purchaseOrderFulfillmentTime;
        this.promotionScheme = params.promotionScheme;
        this.tip = params.tip;
        makeAutoObservable(this);
    }
    get restaurantId() {
        return this.restaurant.id;
    }
    get consents() {
        return this.orderReceiver.consents;
    }
    get contactDetails() {
        return this.orderReceiver.contactDetails;
    }
    get invoiceDetails() {
        return this.orderReceiver.invoiceRequest;
    }
    addOrderLine(orderLine) {
        return this.payload.addOrderLine(orderLine);
    }
    get orderLines() {
        return this.payload.orderLines;
    }
    clear() {
        this.payload.clear();
        this.promotionScheme.clear();
    }
    get numberOfItems() {
        return this.payload.numberOfItems;
    }
    get packaging() {
        if (this.fulfillmentInstructions.fulfillmentMethodType === "DineIn") {
            return Packing.empty();
        }
        return this.restaurant.packingAlgorithm.pack(this.payload.packingItems);
    }
    get packagingPrice() {
        return this.packaging.price;
    }
    get minOrderValue() {
        if (this.fulfillmentInstructions.isDelivery) {
            return this.delivery.minOrderValue;
        }
        if (this.fulfillmentInstructions.fulfillmentMethodType === "Takeaway") {
            return this.restaurant.minOrderValue.takeaway;
        }
        return Money.zero();
    }
    get deliveryCost() {
        if (this.fulfillmentInstructions.isDelivery) {
            return this.delivery.cost;
        }
        return Money.zero();
    }
    get validationPrice() {
        const priceWithoutDelivery = PurchaseOrderPice.priceWithoutDelivery(this).finalValue;
        if (priceWithoutDelivery === null) {
            return null;
        }
        return PurchaseOrderValidationPrice.create({
            deliveryInTotal: this.restaurant.orderingPolicy.deliveryInTotal,
            priceWithoutDelivery,
            deliveryCost: this.deliveryCost,
        });
    }
    get orderValueAvailability() {
        if (this.payload.isEmpty) {
            return Availability.available();
        }
        if (this.validationPrice === null) {
            return Availability.unavailable("MissingParameterValues");
        }
        if (!this.restaurant.orderingPolicy.validate(this.validationPrice)) {
            return Availability.unavailable("MaximumOrderValueExceeded");
        }
        if (this.validationPrice.value.lt(this.minOrderValue)) {
            return Availability.unavailable("InsufficientOrderValue");
        }
        return Availability.available();
    }
    get deliveryAvailability() {
        if (!this.fulfillmentInstructions.isDelivery) {
            return Availability.available();
        }
        const validationPrice = this.validationPrice;
        if (validationPrice === null) {
            return Availability.unavailable("MissingParameterValues");
        }
        return this.delivery.availability(validationPrice);
    }
    get availability() {
        return Availability.composite([
            this.cartAvailability,
            this.deliveryAvailability,
            this.orderReceiver.availability,
            this.fulfillmentInstructions.availability,
            this.payment.availability,
            this.purchaseOrderFulfillmentTime.availability,
        ]);
    }
    get canPlaceOrder() {
        return this.availability.isAvailable.isDefinitelyTrue;
    }
    get cartAvailability() {
        return Availability.composite([
            this.orderingAvailability.availability,
            Availability.dependent("PurchaseOrderPayload", "", this.payload.availability),
            this.orderValueAvailability,
        ]);
    }
    get isReadyForCheckout() {
        return this.cartAvailability.isAvailable.isDefinitelyTrue;
    }
    get checkoutAvailability() {
        return Availability.composite([
            this.orderReceiver.availability,
            this.fulfillmentInstructions.availability,
            this.payment.availability,
            this.purchaseOrderFulfillmentTime.availability,
        ]);
    }
    get isCheckoutFormValid() {
        return this.checkoutAvailability.isAvailable.isDefinitelyTrue;
    }
    /**
     * @returns data to be sent to the backend
     */
    checkoutData(params) {
        const { customer, vatId } = this.orderReceiver.checkoutData;
        return {
            checkout: {
                restaurantId: this.restaurant.id.value,
                items: this.payload.checkoutData,
                locale: params.locale,
                ...this.fulfillmentInstructions.checkoutData,
                paymentMethod: this.payment.method,
                customer,
                origin: this.origin.checkoutData,
                extraDiscount: null,
                draft: null,
                vatId,
                packagingContainers: this.packaging.checkoutData,
                waiterPin: null,
                tip: this.tip.checkoutData,
                fieldTestExperiments: params.fieldTestExperiments,
                isIosReviewMode: false,
                ...this.promotionScheme.checkoutData,
            },
            origin: params.appOrigin,
            payment: this.payment.checkoutData,
            tracking: params.tracking,
        };
    }
    get checkoutLoggingData() {
        var _a;
        return {
            paymentMethod: this.payment.method,
            paymentChannel: JSON.stringify((_a = this.payment.checkoutData) === null || _a === void 0 ? void 0 : _a.channel),
            fulfillmentTime: this.fulfillmentInstructions.checkoutData.requestedFulfillmentTime,
            fulfillmentMethodType: this.fulfillmentInstructions.checkoutData.fulfillmentMethod.tag,
            promotionCode: JSON.stringify(this.promotionScheme.checkoutData.couponCodes),
            items: this.payload.checkoutData.map((productItem) => JSON.stringify(productItem)),
        };
    }
}
