import { RArray } from "../collections";
import { makeImmutable } from "./makeImmutable";
export class DateRange {
    constructor(params) {
        this.begin = params.begin;
        this.end = params.end;
        makeImmutable(this);
    }
    eq(other) {
        return this.begin.eq(other.begin) && this.end.eq(other.end);
    }
    static fromDates(params) {
        if (params.begin.tz !== params.end.tz) {
            throw new Error("Date range timezone mismatch");
        }
        if (params.begin.isAfter(params.end)) {
            throw new Error("Beginning of date range cannot be after ending");
        }
        return new DateRange(params);
    }
    get tz() {
        return this.begin.tz;
    }
    includes(date) {
        return date.isBetween(this.begin, this.end);
    }
    get days() {
        const dates = [];
        for (let date = this.begin.startOfDay(); date.isBefore(this.end); date = date.add(1, "day")) {
            dates.push(date);
        }
        return new RArray(dates);
    }
    toString() {
        return `${this.begin.toString} - ${this.end.toString}`;
    }
    trimBegin(date) {
        const trimmedBegin = this.begin.isBefore(date) ? date : this.begin;
        if (this.end.isAfter(trimmedBegin)) {
            return new DateRange({ begin: trimmedBegin, end: this.end });
        }
        return null;
    }
    extendEnd(newEnd) {
        return new DateRange({ begin: this.begin, end: this.end.max(newEnd) });
    }
}
