import I18n from "i18n-js";
import { makeAutoObservable } from "mobx";
import { Availability, OnTimeFulfillmentTime, } from "../../ordering";
export class PTodayFulfillmentTime {
    constructor(params) {
        this.type = "Today";
        this.hourValue = null;
        this.purchaseOrderFulfillmentTime = params.purchaseOrderFulfillmentTime;
        this.label = I18n.t("orders.form.choose_time");
        makeAutoObservable(this);
    }
    get isAvailable() {
        return this.purchaseOrderFulfillmentTime.todayOptionAvailable;
    }
    get todayHours() {
        return this.purchaseOrderFulfillmentTime.todayHours;
    }
    get timeOptions() {
        return this.todayHours.raw.map((hour) => ({
            label: hour.fullHour,
            value: hour.fullHour,
        }));
    }
    get domainDateValue() {
        // NOTICE Consider 2 level map lookup if performance would be an issue
        return this.todayHours.findWhere((hour) => hour.fullHour === this.hourValue);
    }
    get domainValue() {
        return this.domainDateValue !== null
            ? new OnTimeFulfillmentTime({ date: this.domainDateValue })
            : null;
    }
    get availability() {
        return Availability.boolean({
            HourValueMissing: this.hourValue === null,
            UnavailableHourValue: this.hourValue !== null && this.domainDateValue === null,
        });
    }
}
