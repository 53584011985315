import { makeAutoObservable } from "mobx";
import { PPaymentMediumGenerator } from "./PPaymentMedium";
export class PRestaurant {
    constructor(params) {
        this.domain = params.domain;
        this.productTypes = params.productTypes;
        this.modifierTypes = params.modifierTypes;
        this.customParameterTypes = params.customParameterTypes;
        this.name = params.name;
        this.menu = params.menu;
        this.address = params.address; // TODO: unify PRestaurantAddress with this model and use the only one?
        this.promotionTypes = params.promotionTypes;
        this.isApplePaySupported = params.isApplePaySupported;
        this.franchiseName = params.franchiseName;
        this.phoneNumbers = params.phoneNumbers;
        makeAutoObservable(this);
    }
    get id() {
        return this.domain.id;
    }
    get enableFod() {
        return this.domain.orderingPolicy.enableFod;
    }
    get enableMarketingConsents() {
        return this.domain.orderingPolicy.enableMarketingConsents;
    }
    get paymentMediums() {
        const { paymentChannels, paymentMethods } = this.domain;
        return PPaymentMediumGenerator.group({
            paymentChannels,
            paymentMethods,
            includeApplePay: this.isApplePaySupported,
        });
    }
    get maxOrderValue() {
        return this.domain.orderingPolicy.maxOrderValue;
    }
}
