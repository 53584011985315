import { DysjointDateRanges, makeImmutable } from "../../core";
export class UnionPreorderSettings {
    constructor(settings) {
        this.settings = settings;
        makeImmutable(this);
    }
    dateRanges(now) {
        return this.settings
            .map((setting) => setting.dateRanges(now))
            .reduce((dateRange, preorderDateRange) => dateRange.union(preorderDateRange), DysjointDateRanges.Empty);
    }
}
