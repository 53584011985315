import { makeAutoObservable, reaction } from "mobx";
export class OnlineFulfillmentFormReactions {
    constructor(params) {
        this.fulfillmentTime = params.fulfillmentTime;
        this.fulfillmentFormController = params.fulfillmentFormController;
        makeAutoObservable(this);
    }
    initialize() {
        if (this._disposer) {
            throw new Error("FulfillmentFormReactions has been already initialized");
        }
        // Controller reactivbity fix - update the controller fields to reflect the domain value
        // NOTICE: Both, "Today" and "InFuture" options may have today dates but the only one is available at the moment)
        this._disposer = reaction(() => this.fulfillmentTime.domain && this.fulfillmentTime.options, () => {
            // Update controller value
            this.fulfillmentFormController.fulfillmentTimeController.restore();
            // Reset unavailable domain value
            if (!this.fulfillmentTime.selectedValueIsAvailable) {
                this.fulfillmentTime.clear();
                this.fulfillmentFormController.fulfillmentTimeController.clear();
            }
        }, { fireImmediately: true });
    }
    dispose() {
        if (this._disposer) {
            this._disposer();
        }
    }
}
