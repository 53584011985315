import I18n from "i18n-js";
import { makeAutoObservable } from "mobx";
import { toPairs } from "../../core";
import { ErrorBox, TextFieldController } from "../../form";
export class DeliveryAddressFormController {
    constructor(params) {
        this.deliveryAddress = params.deliveryAddress;
        const { streetAddress } = params.deliveryAddress;
        const onChange = () => {
            if (params.onChange !== undefined) {
                params.onChange();
            }
        };
        this.street = new TextFieldController({
            initialValue: streetAddress.street,
            onUpdate: (str) => {
                streetAddress.street = str;
                onChange();
            },
            errorBox: new ErrorBox({
                domainObjects: [this.deliveryAddress],
                errorMessages: {
                    StreetMissing: I18n.t("_common.form.errors.required"),
                },
            }),
        });
        this.streetNumber = new TextFieldController({
            initialValue: streetAddress.streetNumber,
            onUpdate: (str) => {
                streetAddress.streetNumber = str;
                onChange();
            },
            errorBox: new ErrorBox({
                domainObjects: [this.deliveryAddress],
                errorMessages: {
                    StreetNumberMissing: I18n.t("_common.form.errors.required"),
                },
            }),
        });
        this.city = new TextFieldController({
            initialValue: streetAddress.city,
            onUpdate: (str) => {
                streetAddress.city = str;
                onChange();
            },
            errorBox: new ErrorBox({
                domainObjects: [this.deliveryAddress],
                errorMessages: {
                    CityMissing: I18n.t("_common.form.errors.required"),
                },
            }),
        });
        this.postCode = new TextFieldController({
            initialValue: streetAddress.postCode,
            onUpdate: (str) => {
                streetAddress.postCode = str;
                onChange();
            },
            errorBox: new ErrorBox({
                domainObjects: [this.deliveryAddress],
                errorMessages: {
                    PostCodeMissing: I18n.t("_common.form.errors.required"),
                },
            }),
        });
        // TODO: (re)move this?
        if (!streetAddress.isEmpty) {
            this.submit();
        }
        makeAutoObservable(this);
    }
    get fields() {
        return {
            street: this.street,
            streetNumber: this.streetNumber,
            city: this.city,
            postCode: this.postCode,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelInvalid = this.deliveryAddress.availability.isAvailable.isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.deliveryAddress;
    }
    get isPostCodeRequired() {
        return this.deliveryAddress.streetAddress.isPostCodeRequired;
    }
    get validationStatus() {
        return this.deliveryAddress.status.state;
    }
    // TODO: rename to blur?
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
    // TODO: rename to submit?
    save() {
        return this.deliveryAddress.save();
    }
    // TODO: (B2C-256) remove it
    saveUnvalidated() {
        return this.deliveryAddress.saveUnvalidated();
    }
    // TODO: (B2C-256) remove it, see PDeliveryAddress comment
    validateAndForceSave() {
        return this.deliveryAddress.forceSave();
    }
}
