import { makeImmutable } from "../../core";
import { CustomParameterMultiSet } from "./CustomParameterMultiSet";
import { InternalParameterMultiSet } from "./InternalParameterMultiSet";
export class ParameterMultiSet {
    constructor(params) {
        this.internal = params.internal;
        this.custom = params.custom;
        makeImmutable(this);
    }
    static empty() {
        return new ParameterMultiSet({
            internal: InternalParameterMultiSet.empty(),
            custom: CustomParameterMultiSet.empty(),
        });
    }
    union(other) {
        return new ParameterMultiSet({
            internal: this.internal.union(other.internal),
            custom: this.custom.union(other.custom),
        });
    }
    intersection(other) {
        return new ParameterMultiSet({
            internal: this.internal.intersection(other.internal),
            custom: this.custom.intersection(other.custom),
        });
    }
}
