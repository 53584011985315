import { makeImmutable } from "../../core/makeImmutable";
/**
 * A container for products and modifiers.
 *
 * For example: a cardboard box for pizzas.
 */
export class PackagingContainerType {
    constructor(params) {
        this.id = params.id;
        this.capacity = params.capacity;
        this.price = params.price;
        this.allowedProductTypes = params.allowedProductTypes;
        this.allowedModifierItemTypes = params.allowedModifierItemTypes;
        makeImmutable(this);
    }
    canContain(subject) {
        switch (subject.type) {
            case "ProductInstance":
                return subject.productTypeIds.isSubsetOf(this.allowedProductTypes);
            case "ModifierItem":
                return this.allowedModifierItemTypes.includes(subject.modifierItemTypeId);
        }
    }
}
