import { RArray, RMutableMap } from "../../collections";
import { DaySecond, makeImmutable } from "../../core";
const MIN_DELAY_AFTER_OPENING = 1 * 3600; //   1 hour
const MIN_DELAY_BEFORE_CLOSING = 0.5 * 3600; // 0.5 hour
const MIN_DELAY_FROM_NOW = 1.5 * 3600; // 1.5 hour
const INCREMENT_INTERVAL = 1800; // 0.5 hour
export class OnlineOrderingHours {
    constructor(params) {
        this.weeklySchedule = params.weeklySchedule;
        this.holidays = params.holidays;
        this.scheduleForDateCache = RMutableMap.empty();
        makeImmutable(this);
    }
    calculateIncrements(params) {
        var _a, _b;
        const start = (_b = (_a = params.from
            .add(params.warmup ? MIN_DELAY_AFTER_OPENING : 0)) === null || _a === void 0 ? void 0 : _a.ceil(INCREMENT_INTERVAL)) !== null && _b !== void 0 ? _b : null;
        const end = params.to.sub(params.cooldown ? MIN_DELAY_BEFORE_CLOSING : 0);
        if (end === null) {
            return [];
        }
        const increments = [];
        for (let curr = start; curr !== null && (curr.isBefore(end) || curr.eq(end)); curr = curr.add(INCREMENT_INTERVAL)) {
            increments.push(curr);
        }
        return increments;
    }
    scheduleForDate(date) {
        return this.scheduleForDateCache.getOrCreate(date.fullDate, () => {
            const defaultSchedule = this.weeklySchedule.dayOfWeek(date.weekday);
            const { override, append } = this.holidays.at(date);
            return (override !== null && override !== void 0 ? override : defaultSchedule).union(append);
        });
    }
    fulfillmentTimes({ now, date }) {
        var _a, _b;
        const yesterdayLast = this.scheduleForDate(date.sub(1, "day")).last;
        const tomorrowFirst = this.scheduleForDate(date.add(1, "day")).first;
        const yesterdayEndsAtMidnight = (_a = yesterdayLast === null || yesterdayLast === void 0 ? void 0 : yesterdayLast.eq(DaySecond.Last)) !== null && _a !== void 0 ? _a : false;
        const tomorrowStartsAtMidnight = (_b = tomorrowFirst === null || tomorrowFirst === void 0 ? void 0 : tomorrowFirst.eq(DaySecond.First)) !== null && _b !== void 0 ? _b : false;
        const result = this.scheduleForDate(date).timeRanges.map(({ from, to }) => this.calculateIncrements({
            from,
            to,
            warmup: !yesterdayEndsAtMidnight || !from.eq(DaySecond.First),
            cooldown: !tomorrowStartsAtMidnight || !to.eq(DaySecond.Last),
        }));
        const firstPossible = date.isSameDate(now)
            ? now.secondOfDay.add(MIN_DELAY_FROM_NOW)
            : DaySecond.First;
        if (firstPossible === null) {
            return RArray.empty();
        }
        return new RArray(result.flat())
            .filtered((daySecond) => daySecond.isAfter(firstPossible) || daySecond.eq(firstPossible))
            .map((daySecond) => date.atDaySecond(daySecond));
    }
    // NOTICE endOfDay returns null when the restaurant was not opened at all or has closed before the date
    endOfDay(date) {
        const todayLast = this.scheduleForDate(date).last;
        if (todayLast === null) {
            return null;
        }
        if (todayLast.eq(DaySecond.Last)) {
            const tomorrow = date.add(1, "day");
            const tomorrowFirstRange = this.scheduleForDate(tomorrow).firstRange;
            if (tomorrowFirstRange !== null &&
                tomorrowFirstRange.from.eq(DaySecond.First)) {
                const tommorowDayEnd = tomorrow.atDaySecond(tomorrowFirstRange.to);
                // NOTICE maximum end of day cannot span more than 24 hours from requested date (edge case of restaurant open 24/7)
                return tomorrow.min(tommorowDayEnd);
            }
        }
        const result = date.atDaySecond(todayLast);
        if (result.isAfter(date)) {
            return result;
        }
        return null;
    }
    nextEventAtDate(date) {
        const { secondOfDay: dateSecondOfDay } = date;
        const dateRange = this.scheduleForDate(date).timeRanges.find((range) => range.to.isAfter(dateSecondOfDay));
        if (dateRange === undefined) {
            // NOTICE Restaurant is closed at that date and the next opening event is outside of that date
            return { type: "OrderingBegins", at: null };
        }
        const tomorrow = date.add(1, "day");
        const tomorrowFirstRange = this.scheduleForDate(tomorrow).firstRange;
        const todayEndsAtMidnight = dateRange.to.eq(DaySecond.Last);
        const tomorrowStartsAtMidnight = tomorrowFirstRange !== null &&
            tomorrowFirstRange.from.eq(DaySecond.First);
        if (dateSecondOfDay.isBetweenExcludingEnd(dateRange.from, dateRange.to)) {
            const at = todayEndsAtMidnight && tomorrowStartsAtMidnight
                ? null // NOTICE Ordering ends outside of the provided date
                : date.atDaySecond(dateRange.to);
            return { type: "OrderingEnds", at };
        }
        return { type: "OrderingBegins", at: date.atDaySecond(dateRange.from) };
    }
}
