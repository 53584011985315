import I18n from "i18n-js";
import { makeImmutable } from "../core/makeImmutable";
/**
 * Allows translating values to a localization
 */
export class LocalizationProvider {
    constructor(params) {
        this.locale = params.locale;
        this.fallbackLocale = params.fallbackLocale;
        this.currency = params.currency;
        makeImmutable(this);
    }
    localize(text) {
        const hasAnyText = Object.keys(text).length > 0;
        if (!hasAnyText) {
            return "";
        }
        const localizedText = text[this.locale];
        if (localizedText !== undefined) {
            return localizedText;
        }
        const fallbackLocalizedText = text[this.fallbackLocale];
        if (fallbackLocalizedText !== undefined) {
            return fallbackLocalizedText;
        }
        for (const anyLocalizedText of Object.values(text)) {
            if (anyLocalizedText !== undefined) {
                return anyLocalizedText;
            }
        }
        return "???";
    }
    formatPrice(money) {
        return this.formatCurrencySimple(money.cents, this.currency);
    }
    formatCurrencySimple(cents, currency) {
        const options = CURRENCY_OPTIONS[currency];
        return I18n.toCurrency(cents / 100, {
            format: options.format,
            precision: options.precision,
            separator: options.separator,
            delimiter: options.delimiter,
            unit: options.unit,
            strip_insignificant_zeros: false,
        });
    }
    formatPriceRange(from, to) {
        return `${this.formatPrice(from)} - ${this.formatPrice(to)}`; // FIXME correct money range formatting
    }
    get currencySymbol() {
        return CURRENCY_OPTIONS[this.currency].unit;
    }
    get currencySeparator() {
        return CURRENCY_OPTIONS[this.currency].separator;
    }
    get currencyPrecision() {
        return CURRENCY_OPTIONS[this.currency].precision;
    }
}
const CURRENCY_OPTIONS = {
    PLN: {
        format: "%n %u",
        precision: 2,
        separator: ",",
        delimiter: " ",
        unit: "zł",
    },
    CZK: {
        format: "%n %u",
        precision: 2,
        separator: ",",
        delimiter: "",
        unit: "Kč",
    },
    GBP: {
        format: "%u%n",
        precision: 2,
        separator: ".",
        delimiter: ",",
        unit: "£",
    },
    USD: {
        format: "%u%n",
        precision: 2,
        separator: ".",
        delimiter: "",
        unit: "$",
    },
    EUR: {
        format: "%n %u",
        precision: 2,
        separator: ",",
        delimiter: "",
        unit: "EUR",
    },
    RUB: {
        format: "%n %u",
        precision: 2,
        separator: ",",
        delimiter: " ",
        unit: "руб.",
    },
    RON: {
        format: "%n %u",
        precision: 2,
        separator: ",",
        delimiter: " ",
        unit: "RON",
    },
    HRK: {
        format: "%n %u",
        precision: 2,
        separator: ",",
        delimiter: " ",
        unit: "kn",
    },
    BRL: {
        format: "%u%n",
        precision: 2,
        separator: ".",
        delimiter: "",
        unit: "R$",
    },
};
export function localizePath(path) {
    if (I18n.locale === I18n.defaultLocale) {
        return path;
    }
    else {
        return `/${I18n.locale}${path}`;
    }
}
