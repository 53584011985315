import { RArray } from "../../collections";
export default class DebugAvailablityVistor {
    constructor() {
        this.errors = [];
    }
    visitAvailable(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [`Available[${availability.unavailabilityReasons}]`];
        }
    }
    visitUnavailable(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [`Unavailable[${availability.unavailabilityReasons}]`];
        }
    }
    visitMaybe(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [`Maybe[${availability.unavailabilityReasons}]`];
        }
    }
    visitAsync(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [`Async[${availability.unavailabilityReasons}]`];
        }
    }
    visitBoolean(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [`Boolean[${availability.unavailabilityReasons}]`];
        }
    }
    visitComposite(availability) {
        const nodes = new RArray(availability.availabilities)
            .filtered((availability) => availability.isAvailable.isDefinitelyFalse)
            .mapOptional((availability) => {
            const vistor = new DebugAvailablityVistor();
            availability.accept(vistor);
            return vistor.errors;
        }).raw;
        this.errors = nodes.flat(); //.map(error => `Composite[${availability.unavailabilityReasons}] -> ${error}`);
    }
    visitDependent(availability) {
        const vistitor = new DebugAvailablityVistor();
        availability.dependency.accept(vistitor);
        //this.errors = vistitor.errors.map(error => `Dependency[${availability.unavailabilityReasons}] -> ${error}`)
        this.errors = vistitor.errors.map((error) => `${availability.unavailabilityReasons}(${availability.description}) -> ${error}`);
    }
    visitParametrized(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [`Parametrized[${availability.unavailabilityReasons}]`];
        }
    }
    visitSchedule(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [`Schedule[${availability.toString()}]`];
        }
    }
    visitPreorder(availability) {
        if (availability.isAvailable.isDefinitelyFalse) {
            this.errors = [`Preorder(${availability.reasons.join(", ")})`];
        }
    }
}
