import { ProductInstancePrice } from "./ProductInstancePrice";
export const OrderLinePrice = {
    unitPrice,
    totalPrice,
};
//TODO add selectors
function unitPrice(orderLine) {
    return ProductInstancePrice.price(ProductInstancePrice.priceSelector(orderLine.productInstance));
}
function totalPrice(orderLine) {
    return unitPrice(orderLine).multiply(orderLine.quantity);
}
