import { RMap } from "../../collections";
import { makeImmutable } from "../../core";
import { MenuSectionItemId, } from "../../ordering";
import { PMenuSectionItem, PMenuSectionItemCreatorContent, PMenuSectionItemSingleProductContent, PProductInstanceVariant, } from "../../presentation";
export class PMenuSectionItemParser {
    constructor(params) {
        this.productTypes = params.productTypes;
        this.customParameterTypes = params.customParameterTypes;
        this.localizationProvider = params.localizationProvider;
        this.variantCreatorFactory = params.variantCreatorFactory;
        this.purchaseOrderPayload = params.purchaseOrderPayload;
        makeImmutable(this);
    }
    variantParameterChoiceName(variant) {
        const parameterChoices = variant.customParameterChoices.choices.entries.map(([customParameterType, customParameterChoice]) => this.customParameterTypes
            .get(customParameterType.id)
            .choices.get(customParameterChoice).name);
        return parameterChoices.raw.join(" / ");
    }
    variant(domainVariant) {
        const instance = this.variantCreatorFactory.createProductInstance(domainVariant);
        if (instance === null) {
            return null;
        }
        return new PProductInstanceVariant({
            instance,
            creator: () => this.variantCreatorFactory.creator(instance),
            purchaseOrderPayload: this.purchaseOrderPayload,
            variantParameterChoiceName: this.variantParameterChoiceName(domainVariant),
            localizationProvider: this.localizationProvider,
        });
    }
    parseSingleProductContent(domainSection, productType) {
        const productVariants = domainSection.variants.get(productType.id);
        const productColumns = domainSection.columns.find(productType.id);
        return new PMenuSectionItemSingleProductContent({
            name: productType._name,
            variants: productVariants.mapOptional((domainVariant) => this.variant(domainVariant)).raw,
            columns: productColumns === null
                ? RMap.empty()
                : productColumns.mapOptional((domainVariant) => this.variant(domainVariant)),
            availabilitySchedule: productType.availabilitySchedule,
            localizationProvider: this.localizationProvider,
        });
    }
    parseCreatorContent(creatorVariant) {
        const variant = this.variant(creatorVariant);
        if (variant === null) {
            return null;
        }
        return new PMenuSectionItemCreatorContent(variant);
    }
    parseContent(params) {
        switch (params.item.tag) {
            case "SingleProduct": {
                const productType = this.productTypes.rawFind(params.item.itemProduct);
                if (productType === null) {
                    return null;
                }
                return this.parseSingleProductContent(params.domainSection, productType);
            }
            case "ProductCreator": {
                const creatorVariant = params.domainSection.creators.rawFind(params.id);
                if (creatorVariant === null) {
                    return null;
                }
                return this.parseCreatorContent(creatorVariant);
            }
            default:
                return null;
        }
    }
    parse(params) {
        const content = this.parseContent(params);
        if (content === null) {
            return null;
        }
        return new PMenuSectionItem({
            id: new MenuSectionItemId(params.id),
            content,
        });
    }
}
