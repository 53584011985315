import I18n from "i18n-js";
import { makeAutoObservable } from "mobx";
export class EnabledCoupon {
    constructor(params) {
        this.type = "Enabled";
        this.promotionService = params.promotionService;
        this.inCartPromotionTypes = params.inCartPromotionTypes;
        this._couponPromotion = null;
        this._state = {
            type: "Form",
            isLoading: false,
            errorMessage: null,
            submit: (couponCode) => this.submitCoupon(couponCode),
        };
        makeAutoObservable(this);
    }
    static async fromStorageData(params) {
        const enabledCoupon = new EnabledCoupon({
            promotionService: params.promotionService,
            inCartPromotionTypes: params.inCartPromotionTypes,
        });
        if (params.couponCode !== null) {
            await enabledCoupon.submitCoupon(params.couponCode);
        }
        return enabledCoupon;
    }
    get state() {
        return this._state;
    }
    get couponPromotion() {
        return this._couponPromotion;
    }
    async submitCoupon(couponCode) {
        this.setCouponStateLoading();
        try {
            const result = await this.promotionService.getCoupon(couponCode);
            if (result === null) {
                // FIXME: translation
                this.setCouponStateError(I18n.t("coupon.invalid"));
            }
            else {
                this.setCouponStateApplied(result.coupon);
                this.addCoupon(result);
            }
        }
        catch (error) {
            // FIXME: translation
            this.setCouponStateError(I18n.t("coupon.fetch_error"));
            console.error(error);
        }
    }
    setCouponStateApplied(coupon) {
        assertState(this._state, "Form");
        this._state = {
            type: "Applied",
            description: coupon.code.value,
            source: coupon.source,
            remove: () => this.removeCoupon(),
        };
    }
    setCouponStateLoading() {
        assertState(this._state, "Form");
        this._state = {
            ...this._state,
            isLoading: true,
            errorMessage: null,
        };
    }
    setCouponStateError(errorMessage) {
        assertState(this._state, "Form");
        this._state = {
            ...this._state,
            isLoading: false,
            errorMessage,
        };
    }
    resetCouponState() {
        this._state = {
            type: "Form",
            isLoading: false,
            errorMessage: null,
            submit: (couponCode) => this.submitCoupon(couponCode),
        };
    }
    addCoupon(result) {
        this._couponPromotion = result.promotion;
        this.inCartPromotionTypes.setCoupon(result.coupon);
    }
    removeCoupon() {
        this.resetCouponState();
        this._couponPromotion = null;
        this.inCartPromotionTypes.setCoupon(null);
    }
    clear() {
        this.removeCoupon();
    }
}
function assertState(state, ...expectedTypes) {
    if (!expectedTypes.includes(state.type)) {
        throw new Error(`Invalid state ${state.type} (expected one of: ${expectedTypes})`);
    }
}
