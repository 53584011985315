import { reaction } from "mobx";
import { PurchaseOrderStorageData } from "../ordering/PurchaseOrder/PurchaseOrderStorageData";
const CURRENT_STORAGE_VERSION = "1";
const STORAGE_FORMAT_VERSION_KEY = "client_storageFormatVersion";
export class PurchaseOrderStorageManager {
    constructor(params) {
        this.reactionDisposers = [];
        this.purchaseOrderFactory = params.purchaseOrderFactory;
        this.cartStorageManager = params.cartStorageManager;
        this.previousCartStorageManager = params.previousCartStorageManager;
        this.fulfillmentTimeStorageManager =
            params.storageManagerFactory.forFulfillmentTime();
        this.streetAddressStorageManager =
            params.storageManagerFactory.forStreetAddress();
        this.apartmentInfoStorageManager =
            params.storageManagerFactory.forApartmentInfo();
        this.contactDetailsStorageManager = params.isQrWaiterOrder
            ? params.storageManagerFactory.forQrWaiterContactDetails()
            : params.storageManagerFactory.forOnlineContactDetails();
        this.invoiceDetailsStorageManager =
            params.storageManagerFactory.forInvoiceDetails();
        this.consentsStoreManager = params.storageManagerFactory.forConsents();
        this.fulfillmentMethodTypeStorageManager =
            params.storageManagerFactory.forFulfillmentMethodType();
        this.paymentStorageManager = params.storageManagerFactory.forPayment();
        this.checkStorageFormatVersion();
    }
    checkStorageFormatVersion() {
        if (localStorage.getItem(STORAGE_FORMAT_VERSION_KEY) !==
            CURRENT_STORAGE_VERSION) {
            this.erase();
            localStorage.setItem(STORAGE_FORMAT_VERSION_KEY, CURRENT_STORAGE_VERSION);
        }
    }
    initialize(storageData) {
        this.reactionDisposers.push(reaction(() => storageData.cartStorageData, (cartStorageData) => this.cartStorageManager.store(cartStorageData)));
        this.reactionDisposers.push(reaction(() => storageData.fulfillmentTimeStorageData, (fulfillmentTimeStorageData) => {
            if (fulfillmentTimeStorageData !== null) {
                this.fulfillmentTimeStorageManager.store(fulfillmentTimeStorageData);
            }
        }, 
        // NOTICE: Firing immediately to ensure that the initial validated value is stored
        { fireImmediately: true }));
        this.reactionDisposers.push(reaction(() => storageData.streetAddressStorageData, (streetAddressStorageData) => {
            if (streetAddressStorageData !== null) {
                this.streetAddressStorageManager.store(streetAddressStorageData);
            }
        }));
        this.reactionDisposers.push(reaction(() => storageData.apartmentInfoStorageData, (apartmentInfoStorageData) => {
            if (apartmentInfoStorageData !== null) {
                this.apartmentInfoStorageManager.store(apartmentInfoStorageData);
            }
        }));
        this.reactionDisposers.push(reaction(() => storageData.contactDetailsStorageData, (contactDetailsStorageData) => {
            this.contactDetailsStorageManager.store(contactDetailsStorageData);
        }));
        this.reactionDisposers.push(reaction(() => storageData.invoiceDetailsStorageData, (invoiceDetailsStorageData) => {
            this.invoiceDetailsStorageManager.store(invoiceDetailsStorageData);
        }));
        this.reactionDisposers.push(reaction(() => storageData.consentsStorageData, (consentsStorageData) => {
            this.consentsStoreManager.store(consentsStorageData);
        }));
        this.reactionDisposers.push(reaction(() => storageData.paymentMediumStorageData, (paymentMediumStorageData) => {
            if (paymentMediumStorageData !== null) {
                this.paymentStorageManager.store(paymentMediumStorageData);
            }
        }, 
        // NOTICE: Firing immediately to ensure that the initial validated value is stored
        { fireImmediately: true }));
        this.reactionDisposers.push(reaction(() => storageData.fulfillmentMethodType, (fulfillmentMethodType) => {
            if (fulfillmentMethodType !== null) {
                this.fulfillmentMethodTypeStorageManager.store(fulfillmentMethodType);
            }
        }, 
        // NOTICE: Firing immediately to ensure that the initial validated value is stored
        { fireImmediately: true }));
    }
    dispose() {
        this.reactionDisposers.forEach((reactionDisposer) => reactionDisposer());
        this.reactionDisposers = [];
    }
    restoreCart() {
        return this.cartStorageManager.restore();
    }
    restore(params) {
        const { purchaseOrder, purchaseOrderScope } = this.purchaseOrderFactory.create({
            restaurant: params.restaurant,
            orderingHours: params.orderingHours,
            orderingAvailability: params.orderingAvailability,
            inCartPromotionTypes: params.inCartPromotionTypes,
            cartStorageData: params.cartStorageData,
            contactDetailsLock: params.contactDetailsLock,
            invoiceRequestStorageData: this.invoiceDetailsStorageManager.restore(),
            consentsStorageData: this.consentsStoreManager.restore(),
            contactDetailsStorageData: this.contactDetailsStorageManager.restore(),
            streetAddressStorageData: this.streetAddressStorageManager.restore(),
            apartmentInfoStorageData: this.apartmentInfoStorageManager.restore(),
            fulfillmentTimeStorageData: this.fulfillmentTimeStorageManager.restore(),
            fulfillmentMethodTypeStorageData: this.fulfillmentMethodTypeStorageManager.restore(),
            paymentStorageData: this.paymentStorageManager.restore(),
            dineInLocation: params.dineInLocation,
            enableDelayedDelivery: params.enableDelayedDelivery,
            allowOrderingInClosedRestaurant: params.allowOrderingInClosedRestaurant,
            enableMenuFulfillmentSettings: params.enableMenuFulfillmentSettings,
        });
        const purchaseOrderStorageData = new PurchaseOrderStorageData({
            purchaseOrder,
            inCartPromotionTypes: params.inCartPromotionTypes,
        });
        this.initialize(purchaseOrderStorageData);
        return { purchaseOrder, purchaseOrderScope };
    }
    erase() {
        this.cartStorageManager.erase();
        this.previousCartStorageManager.erase();
        this.fulfillmentTimeStorageManager.erase();
        this.streetAddressStorageManager.erase();
        this.apartmentInfoStorageManager.erase();
        this.contactDetailsStorageManager.erase();
        this.invoiceDetailsStorageManager.erase();
        this.consentsStoreManager.erase();
    }
}
