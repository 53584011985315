import { IdentifiableMap } from "../../collections";
import { makeImmutable } from "../../core";
import { randomUUID } from "../../id";
import { ProductConfig, ProductConfigScope, ProductInstance, ProductInstanceCreator, ProductInstanceHalves, ProductInstancePart, ProductInstanceTrackingId, SingleProductInstancePart, } from "../../ordering";
export class VariantCreatorFactory {
    constructor(params) {
        this.productCategories = params.productCategories;
        this.productTypes = params.productTypes;
        this.purchaseOrderScope = params.purchaseOrderScope;
        makeImmutable(this);
    }
    createProductInstance(variant) {
        const customParameterInstances = variant.customParameterChoices.parameterInstances;
        const menuSectionItemScope = new ProductConfigScope({
            parentScope: this.purchaseOrderScope,
            customParameterInstances,
        });
        customParameterInstances.objects.forEach((customParameterInstance) => customParameterInstance.setScope(menuSectionItemScope));
        const { commonConfig, partsConfig } = this.productConfigs(customParameterInstances);
        const parts = this.creatorParts(variant.productTypes, partsConfig, menuSectionItemScope);
        if (parts === null) {
            return null;
        }
        return new ProductInstance({
            commonConfig,
            parts,
            trackingId: new ProductInstanceTrackingId(randomUUID()),
        });
    }
    productConfigs(customParameterInstances) {
        const { true: divisibleParameterInstances, false: nonDivisibleParameterInstances, } = customParameterInstances.partition((customParameterInstance) => customParameterInstance.customParameterType.isDivisible);
        const commonConfig = new ProductConfig({
            modifierInstances: IdentifiableMap.empty(),
            customParameterInstances: nonDivisibleParameterInstances,
        });
        const partsConfig = new ProductConfig({
            modifierInstances: IdentifiableMap.empty(),
            customParameterInstances: divisibleParameterInstances,
        });
        return { commonConfig, partsConfig };
    }
    creatorParts(productTypes, partsConfig, menuSectionItemScope) {
        switch (productTypes.type) {
            case "Single":
                return new SingleProductInstancePart(new ProductInstancePart({
                    scope: menuSectionItemScope,
                    productType: productTypes.productType,
                    productConfig: partsConfig,
                }));
            case "Halves":
                return ProductInstanceHalves.create({
                    scope: menuSectionItemScope,
                    productCategory: this.productCategories.get(productTypes.first.productCategoryId),
                    first: new ProductInstancePart({
                        scope: menuSectionItemScope,
                        productType: productTypes.first,
                        productConfig: partsConfig,
                    }),
                    second: new ProductInstancePart({
                        scope: menuSectionItemScope,
                        productType: productTypes.second,
                        productConfig: partsConfig,
                    }),
                });
        }
    }
    creator(productInstance) {
        const creator = ProductInstanceCreator.create({
            productCategories: this.productCategories,
            productTypes: this.productTypes,
            productInstance,
        });
        creator.initWithScope(this.purchaseOrderScope);
        return creator;
    }
}
