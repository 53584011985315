import I18n from "i18n-js";
import { makeAutoObservable } from "mobx";
import { toPairs } from "../../core";
import { CheckboxFieldController, ErrorBox, TextFieldController, } from "../../form";
import { InvoiceRequest, VatId } from "../../ordering";
export class InvoiceFormController {
    constructor(params) {
        var _a, _b;
        this.orderReceiver = params.orderReceiver;
        this.vatId = new TextFieldController({
            initialValue: (_b = (_a = this.orderReceiver.invoiceRequest) === null || _a === void 0 ? void 0 : _a.vatId.toString()) !== null && _b !== void 0 ? _b : "",
            onUpdate: (value) => {
                this.orderReceiver.invoiceRequest = new InvoiceRequest({
                    vatId: new VatId(value),
                });
            },
            errorBox: new ErrorBox({
                domainObjects: [this.orderReceiver],
                errorMessages: {
                    VatIdMissing: I18n.t("_common.form.errors.required"),
                    VatIdInvalid: I18n.t("activerecord.errors.models.order.attributes.vat_id.invalid"),
                },
            }),
        });
        this.isInvoiceNeeded = new CheckboxFieldController({
            initialValue: this.orderReceiver.invoiceRequest !== null,
            onUpdate: (value) => {
                if (value) {
                    this.vatId.change("");
                }
                else {
                    this.orderReceiver.invoiceRequest = null;
                }
            },
        });
        makeAutoObservable(this);
    }
    get fields() {
        return {
            isInvoiceNeeded: this.isInvoiceNeeded,
            vatId: this.vatId,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelInvalid = this.orderReceiver.invoiceAvailability.isAvailable.isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.orderReceiver;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
}
