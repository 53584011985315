import { DaySecond, FULL_DAY } from "./DaySecond";
import { makeImmutable } from "./makeImmutable";
/**
 * A period of time from a given day second to a given day second.
 */
export class TimeRange {
    constructor(params) {
        this.from = params.from;
        this.to = params.to;
        makeImmutable(this);
    }
    static fromRange(params) {
        if (params.from.isAfter(params.to)) {
            throw new Error("Beginning of time range cannot be after ending");
        }
        return new TimeRange(params);
    }
    get duration() {
        return this.from.duration(this.to);
    }
    get isEmpty() {
        return this.duration === 0;
    }
    get isFullDay() {
        return this.duration === FULL_DAY;
    }
    eq(other) {
        return this.from.eq(other.from) && this.to.eq(other.to);
    }
    /**
     * Does the provided date fit into the time range?
     */
    includes(date) {
        return date.secondOfDay.isBetweenExcludingEnd(this.from, this.to);
    }
    intersection(other) {
        const from = this.from.max(other.from);
        const to = this.to.min(other.to);
        if (from.isBefore(to)) {
            return new TimeRange({ from, to });
        }
        return TimeRange.Empty;
    }
    extendEnd(newEnd) {
        return new TimeRange({ from: this.from, to: this.to.max(newEnd) });
    }
    toString() {
        return `${this.from.toString()} - ${this.to.toString()}`;
    }
}
TimeRange.Empty = new TimeRange({ from: DaySecond.First, to: DaySecond.First });
TimeRange.FullDay = new TimeRange({ from: DaySecond.First, to: DaySecond.Last });
