import StickyHeader from "../components/StickyHeader";
function getAnchorFromLink(link) {
    return link.getAttribute("data-target") || link.getAttribute("href");
}
/**
 * Get target element from link URL or target ID.
 */
function getTargetFromHashLink(link) {
    const anchor = getAnchorFromLink(link);
    const el = document.getElementById(anchor.split("#")[1]);
    return el ? el : null;
}
/**
 * Scroll to specific element.
 */
export function scrollToElement(target) {
    if (!target)
        return;
    const scrollToElementEvent = new Event("to-element.restaumatic.scroll", {
        bubbles: false,
        cancelable: true,
        composed: true,
    });
    document.dispatchEvent(scrollToElementEvent);
    // Adding scrollMarginTop to target when sticky header exist
    if (StickyHeader.utils.isHeaderSticky()) {
        target.style.scrollMarginTop = `${StickyHeader.utils.getStickyHeaderHeight()}px`;
    }
    target.scrollIntoView({
        behavior: "smooth",
        block: "start",
    });
}
/**
 * Scroll to element if target is above our position (it's very likely that user expects it).
 */
export function scrollIfAbove(target) {
    if (!target) {
        return false;
    }
    const offsetTop = target.getBoundingClientRect().top;
    const distanceTop = Math.round(offsetTop - StickyHeader.utils.getStickyHeaderHeight());
    if (distanceTop < 0) {
        scrollToElement(target);
        return true;
    }
    return false;
}
/**
 * Scroll to element if target is below our position.
 */
export function scrollIfBelow(target) {
    if (!target) {
        return false;
    }
    const offsetBottom = target.getBoundingClientRect().bottom;
    const distanceBottom = Math.round(offsetBottom - window.innerHeight);
    if (distanceBottom > 0) {
        scrollToElement(target);
        return true;
    }
    return false;
}
/**
 * Scroll to element if target is not visible (below or above our position).
 */
export function scrollIfNotVisible(target) {
    return scrollIfAbove(target) || scrollIfBelow(target);
}
/**
 * Init scroll utility for specific anchor links.
 */
export function initScroll(anchorPrefix = "section-") {
    const anchors = document.querySelectorAll(`a[href*="#${anchorPrefix}"]`);
    anchors.forEach((item) => {
        item.addEventListener("click", (e) => {
            const link = e.currentTarget;
            const target = getTargetFromHashLink(link);
            if (!target)
                return;
            history.pushState(null, "", getAnchorFromLink(link));
            scrollToElement(target);
            e.preventDefault();
        });
    });
}
/**
 * Scroll to the first error element within the parent element if not visible or flash it if visible.
 *
 * @param parentSelector Selector of the element to search within
 * @param errorSelectors Search for these elements within the parent (the order matters)
 */
export function scrollToError(parentSelector, errorSelectors) {
    // Get parent element
    const parentElement = document.querySelector(parentSelector);
    if (!parentElement) {
        return;
    }
    // Get first error element
    const errorElement = errorSelectors
        .map((selector) => parentElement.querySelector(selector))
        .filter(Boolean)
        .shift();
    if (!errorElement) {
        return;
    }
    // Try to scroll (if not visible)
    const scrolled = scrollIfNotVisible(errorElement);
    // "Flash" if already visible
    if (!scrolled) {
        const root = window.document.querySelector(":root");
        if (!root) {
            return;
        }
        const themeBaseDuration = getComputedStyle(root).getPropertyValue("--base-duration") || "0.3"; // in s
        const animationDuration = parseFloat(themeBaseDuration) * 1000; // in ms
        errorElement.style.animation = `fadeIn ${animationDuration}ms`;
        setTimeout(() => {
            errorElement.style.animation = "";
        }, animationDuration);
    }
}
