import { Availability } from "../ordering/Availability";
import { makeImmutable } from "../core/makeImmutable";
// FIXME Regex for compatibility reasons
const EMAIL_REGEX = /^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+$/;
/**
 * Email
 */
export class Email {
    constructor(value) {
        // TODO: remove spaces?
        // pendolino/client/purs/src/Restaumatic/Site/Components/User.purs#L217-L218
        this.value = value;
        makeImmutable(this);
    }
    static empty() {
        return new Email("");
    }
    get isEmpty() {
        return this.value.length === 0;
    }
    toString() {
        return this.value;
    }
    get valid() {
        return EMAIL_REGEX.test(this.value);
    }
    get availability() {
        return Availability.boolean({
            EmailMissing: this.value.length === 0,
            InvalidEmail: !this.valid,
        });
    }
}
