import { IdMap, RArray, RMap, RSet } from "../../collections";
import { CustomParameterInstance } from "../CustomParameterInstance";
import { ParameterSetImpl } from "./ParameterSetImpl";
import { makeImmutable } from "../../core/makeImmutable";
import { IdentifiableMap } from "../../collections/IdentifiableMap";
import { CustomParameterMultiSet } from "../ParameterMultiSet";
/**
 * A single combination of different internal parameters.
 *
 * For example: "size: 30cm, dough: thick"
 *
 * The restaurant owner can define their own custom parameters, such as "pizza size"
 */
export class CustomParameterSet {
    constructor(impl) {
        this.impl = impl;
        makeImmutable(this);
    }
    static fromRMap(parameterValues) {
        return new CustomParameterSet(new ParameterSetImpl({
            parameterValues,
            valueComparator: (a, b) => a.eq(b),
        }));
    }
    static fromChoices(parameterChoices) {
        for (const [parameterType, parameterChoiceId] of parameterChoices) {
            if (!parameterType.includesChoice(parameterChoiceId)) {
                throw new Error(`Parameter type ${parameterType.id} does not allow choice ${parameterChoiceId}`);
            }
        }
        return CustomParameterSet.fromRMap(parameterChoices);
    }
    static singleton(parameterType, parameterChoiceId) {
        return CustomParameterSet.fromRMap(RMap.singleton(parameterType, parameterChoiceId));
    }
    get choices() {
        return this.impl.parameterValues;
    }
    static fromInstances(customParameterInstances) {
        const parameterChoices = customParameterInstances.objects.flatMap((customParameterInstance) => customParameterInstance.selectedChoice
            ? new RArray([
                [
                    customParameterInstance.customParameterType,
                    customParameterInstance.selectedChoice,
                ],
            ])
            : RArray.empty());
        return CustomParameterSet.fromChoices(RMap.fromIterable(parameterChoices));
    }
    get parameterInstances() {
        return IdentifiableMap.fromIterable("typeId", [...this.impl.parameterValues].map(([parameterType, parameterChoiceId]) => CustomParameterInstance.create(parameterType, parameterChoiceId)));
    }
    get parameterTypes() {
        return IdMap.fromIterable(this.impl.types);
    }
    get choiceIds() {
        return RSet.fromIterable(this.impl.parameterValues.values);
    }
    get isEmpty() {
        return this.impl.isEmpty;
    }
    static empty() {
        return CustomParameterSet.fromRMap(RMap.empty());
    }
    unify(other) {
        const result = this.impl.unify(other.impl);
        switch (result.type) {
            case "success":
                return {
                    type: "success",
                    matchingParameters: new CustomParameterMultiSet(result.matchingParameters),
                    value: new CustomParameterSet(result.value),
                };
            case "failure":
                return {
                    type: "failure",
                    matchingParameters: new CustomParameterMultiSet(result.matchingParameters),
                    incompatibleParameters: new CustomParameterMultiSet(result.incompatibleParameters),
                };
        }
    }
    /**
     * Unites two CustomParameterSets
     *
     * Fails if predicates from one set conflict with predicates from another set
     */
    union(other) {
        const result = this.impl.union(other.impl);
        if (result === null) {
            return null;
        }
        return new CustomParameterSet(result);
    }
    find(customParameterType) {
        return this.impl.parameterValues.find(customParameterType);
    }
    findById(customParameterTypeId) {
        return this.impl.parameterValues.rawFind(customParameterTypeId.value);
    }
}
