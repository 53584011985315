import { DysjointDateRanges, makeImmutable, } from "../../core";
export class AbsoluteDatePreorderSetting {
    constructor(range) {
        this.type = "AbsoluteDate";
        this.range = range;
        makeImmutable(this);
    }
    eq(other) {
        return this.range.eq(other.range);
    }
    dateRanges(now) {
        const trimmed = this.range.trimBegin(now);
        return trimmed === null
            ? DysjointDateRanges.Empty
            : DysjointDateRanges.singleton(trimmed);
    }
}
