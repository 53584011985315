import { makeAutoObservable } from "mobx";
import { Availability } from "../Availability";
export class Consents {
    constructor(params) {
        this.tosAgreement = params.tosAgreement;
        this.subscribeEmail = params.subscribeEmail;
        this.subscribePhone = params.subscribePhone;
        makeAutoObservable(this);
    }
    static empty() {
        return new Consents({
            tosAgreement: false,
            subscribeEmail: false,
            subscribePhone: false,
        });
    }
    static fromStorageData(storageData) {
        if (storageData === null) {
            return Consents.empty();
        }
        return new Consents(storageData);
    }
    get availability() {
        return Availability.boolean({
            TermsOfServiceNotAccepted: !this.tosAgreement,
        });
    }
    get checkoutData() {
        return {
            subscribed: false,
            subscribeEmail: this.subscribeEmail,
            subscribePhone: this.subscribePhone,
            // TODO: this is used in Apetilo only
            subscribedRestaumatic: false,
            subscribeRestaumaticEmail: false,
            subscribeRestaumaticPhone: false,
        };
    }
    get fields() {
        return {
            tosAgreement: this.tosAgreement,
            subscribeEmail: this.subscribeEmail,
            subscribePhone: this.subscribePhone,
        };
    }
    get storageData() {
        return {
            tosAgreement: this.tosAgreement,
            subscribeEmail: this.subscribeEmail,
            subscribePhone: this.subscribePhone,
        };
    }
}
