/**
 * Tracking module allows you to send analytics events to multiple platforms, eg. Google Analytics V4, Facebook, Restaumatic using Skubacz.tracking mechanism.
 * TODO: add Skubacz.tracking as a dependency
 */
export const Tracking = {
    trackEvent,
    trackEventUnique,
};
class Restaumatic {
    static toPlatformEvent(event) {
        return {
            name: event.tag,
            value: "contents" in event ? event.contents : {},
        };
    }
}
class AnalyticsV4 {
    static itemValue(item) {
        return item.price * item.quantity;
    }
    static convertItem(item) {
        var _a;
        return {
            item_name: item.name,
            quantity: item.quantity,
            price: item.price,
            item_category: item.category,
            item_list_id: (_a = item.list) !== null && _a !== void 0 ? _a : undefined,
        };
    }
    static removeFromCart(currency, item) {
        return {
            currency: currency,
            value: this.itemValue(item),
            items: [this.convertItem(item)],
        };
    }
    static purchase(currency, item) {
        return {
            items: item.items.map((item) => this.convertItem(item)),
            value: item.total,
            currency: currency,
            affiliation: item.affiliation,
            shipping: item.shipping,
            transaction_id: item.transactionId,
            coupon: item.coupon,
        };
    }
    static toPlatformEvent(currency, event) {
        switch (event.tag) {
            case "AddToCart":
                return {
                    name: "add_to_cart",
                    value: {
                        currency: currency,
                        value: this.itemValue(event.contents),
                        items: [this.convertItem(event.contents)],
                    },
                };
            case "LocateMe":
                return { name: "locate_me", value: {} };
            case "RemoveFromCart":
                return {
                    name: "remove_from_cart",
                    value: this.removeFromCart(currency, event.contents),
                };
            case "BeginCheckout":
                return { name: "begin_checkout", value: event.contents };
            case "Purchase":
                return {
                    name: "purchase",
                    value: this.purchase(currency, event.contents),
                };
            case "SetCheckoutOption":
                return { name: "set_checkout_option", value: event.contents };
            case "SuggestionResult":
                return undefined;
            case "ExperienceImpression":
                return {
                    name: "experience_impression",
                    value: {
                        exp_variant_string: `RE-${event.contents.experiment}-${event.contents.variant}`,
                    },
                };
            case "NotOverlappingDishes":
                return { name: "not_overlapping_dishes", value: event.contents };
            case "VisitPage":
                return { name: "visit_page", value: { page: event.contents.page.tag } };
            case "ChooseDelivery":
                return {
                    name: "choose_delivery",
                    value: { page: event.contents.page.tag },
                };
            case "ChooseTakeaway":
                return {
                    name: "choose_takeaway",
                    value: { page: event.contents.page.tag },
                };
            case "ChooseAddress":
                return {
                    name: "choose_address",
                    value: { page: event.contents.page.tag },
                };
            case "ChooseDineIn":
                return {
                    name: "choose_dinein",
                    value: { page: event.contents.page.tag },
                };
            case "ChooseDate":
                return {
                    name: "choose_date",
                    value: { page: event.contents.page.tag },
                };
            case "ReviewGoogleLinkShown":
                return { name: "review_google_link_shown", value: {} };
            case "OrderingImpossibleAlert":
                return { name: "ordering_impossible_alert", value: {} };
            default:
                throw new Error("Unknown event");
        }
    }
}
class Facebook {
    static itemValue(item) {
        return item.price * item.quantity;
    }
    static itemToContent(item) {
        if (item.ids.length > 0) {
            return item.ids.map((id) => ({ id: id, quantity: item.quantity }));
        }
        else {
            return [{ id: item.name, quantity: item.quantity }];
        }
    }
    static addToCart(currency, item) {
        return {
            content_type: item.ids.length > 1 ? "product_group" : "product",
            contents: this.itemToContent(item),
            value: this.itemValue(item),
            currency: currency,
        };
    }
    static beginCheckout(currency, item) {
        return {
            contents: item.items.flatMap((item) => this.itemToContent(item)),
            value: item.items
                .map((item) => this.itemValue(item))
                .reduce((acc, el) => acc + el, 0),
            num_items: item.items.length,
            currency: currency,
        };
    }
    static purchase(currency, item) {
        return {
            contents: item.items.flatMap((item) => this.itemToContent(item)),
            value: item.total,
            currency: currency,
        };
    }
    static toPlatformEvent(currency, event) {
        switch (event.tag) {
            case "AddToCart":
                return {
                    name: "AddToCart",
                    value: this.addToCart(currency, event.contents),
                };
            case "BeginCheckout":
                return {
                    name: "InitiateCheckout",
                    value: this.beginCheckout(currency, event.contents),
                };
            case "Purchase":
                return {
                    name: "Purchase",
                    value: this.purchase(currency, event.contents),
                };
            case "VisitPage":
                if (event.contents.page.tag === "ProductCard") {
                    return {
                        name: "ViewContent",
                        value: {
                            content_type: event.contents.page.productIds.length > 1
                                ? "product_group"
                                : "product",
                            content_ids: event.contents.page.productIds,
                        },
                    };
                }
                else {
                    return undefined;
                }
            case "LocateMe":
            case "RemoveFromCart":
            case "SetCheckoutOption": // TODO: we could support setting payment method!
            case "SuggestionResult":
            case "ExperienceImpression":
            case "NotOverlappingDishes":
            case "ReviewGoogleLinkShown":
                return undefined;
            case "ChooseDelivery":
                return undefined;
            case "ChooseTakeaway":
                return undefined;
            case "ChooseAddress":
                return undefined;
            case "ChooseDineIn":
                return undefined;
            case "ChooseDate":
                return undefined;
            case "OrderingImpossibleAlert":
                return undefined;
            default:
                throw new Error("Unknown event");
        }
    }
}
function toNamedEvent(currency, event) {
    return {
        analytics_v4: AnalyticsV4.toPlatformEvent(currency, event),
        facebook_v2: Facebook.toPlatformEvent(currency, event),
        restaumatic: Restaumatic.toPlatformEvent(event),
    };
}
function getCurrencyEff() {
    return Skubacz.configuration.currency;
}
function trackEvent(event) {
    trackEventEff(toNamedEvent(getCurrencyEff(), event))();
}
function trackEventEff(event) {
    return function () {
        Skubacz.tracking({ tag: "TrackNamedEvent", event: event });
    };
}
// Send an event to all tracking platforms only once (dedup key stored in sessionStorage)
//
// See `Skubacz.trackNamedEvent` on the JS side.
function trackEventUnique(eventKey, event) {
    trackEventUniqueEff(eventKey)(toNamedEvent(getCurrencyEff(), event))();
}
function trackEventUniqueEff(eventKey) {
    return function (event) {
        return function () {
            const key = "tracking_" + eventKey;
            // Local storage needs to be used here, because some mobile clients will kill and reload a tab multiple times,
            // causing duplicate events
            if (localStorage.getItem(key) !== "true") {
                Skubacz.tracking({ tag: "TrackNamedEvent", event: event });
                localStorage.setItem(key, "true");
            }
        };
    };
}
