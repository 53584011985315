import I18n from "i18n-js";
export function formatFulfillmentMethodType(type) {
    switch (type) {
        case "Delivery":
            return I18n.t("delivery_method.delivery");
        case "NoContactDelivery":
            return I18n.t("delivery_method.no_contact_delivery");
        case "Takeaway":
            return I18n.t("delivery_method.takeaway");
        case "DineIn":
            return I18n.t("delivery_method.dine_in");
    }
}
export function formatFulfillmentMethodTypeShort(type) {
    switch (type) {
        case "Delivery":
            return I18n.t("delivery_method.delivery");
        case "NoContactDelivery":
            return I18n.t("delivery_method.no_contact_delivery");
        case "Takeaway":
            return I18n.t("delivery_method.takeaway_short");
        case "DineIn":
            return I18n.t("delivery_method.dine_in_short");
    }
}
