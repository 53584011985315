import { IdMap, RArray, RMap, RMutableMap } from "../collections";
import { toPairs } from "../core";
import { makeImmutable } from "../core/makeImmutable";
import { Parametrization, PreorderSettings, ProductTypeGroup, ProductTypeGroupId, } from "../ordering";
import { Menu } from "../ordering/Menu";
import { ProductTypeId } from "../ordering/ProductType";
import { Schedule, ScheduleId } from "../ordering/Schedule";
import { WeeklySchedule } from "../ordering/WeeklySchedule";
import { AvailabilitySpecParser } from "./AvailabilitySpecParser";
import { CustomParameterTypeParser } from "./CustomParameterTypeParser";
import { HalvingPolicyParser } from "./HalvingPolicyParser";
import { MenuArrangmentParser } from "./MenuArrangmentParser";
import { MenuSectionParser } from "./MenuSectionParser";
import { ModifierItemTypeParser } from "./ModifierItemTypeParser";
import { ModifierSettingsParser } from "./ModifierSettingsParser";
import { ModifierTypeParser } from "./ModifierTypeParser";
import { ParametrizationParser } from "./ParametrizationParser";
import { ProductCategoryParser } from "./ProductCategoryParser";
import { ProductTypeParser } from "./ProductTypeParser";
/**
 * Transforms MenuV2 from backend to restaumatic-client objects.
 */
export class MenuV2Parser {
    constructor(params) {
        this.catalogue = params.catalogue;
        this.appOrigin = params.appOrigin;
        this.onlineOrderingSettings = params.onlineOrderingSettings;
        this.timezone = params.timezone;
        this.orderingHours = params.orderingHours;
        makeImmutable(this);
    }
    parseMenu() {
        const customParameterTypeParser = new CustomParameterTypeParser();
        const customParameterTypes = this.parseCustomParameterTypes(customParameterTypeParser);
        const parametrizationParser = new ParametrizationParser({
            customParameterTypes,
        });
        const availabilitySpecParser = new AvailabilitySpecParser({
            parametrizationParser,
        });
        // NOTICE fix for dependency loop
        customParameterTypeParser.setCustomParameterChoiceAvailabilitySpecs(availabilitySpecParser);
        const modifierSettingsParser = new ModifierSettingsParser();
        const modifierItemTypeParser = new ModifierItemTypeParser({
            parametrizationParser,
            availabilitySpecParser,
        });
        const modifierTypeParser = new ModifierTypeParser({
            parametrizationParser,
            modifierItemTypeParser,
        });
        const modifierTypes = this.parseModifierTypes(modifierTypeParser);
        const halvingPolicyParser = new HalvingPolicyParser({
            parametrizationParser,
        });
        const productCategoryParser = new ProductCategoryParser({
            halvingPolicyParser,
            modifierTypes,
        });
        const schedules = this.parseSchedules();
        const productCategories = this.parseProductCategories(productCategoryParser);
        const productTypeGroups = this.parseProductTypeGroups();
        const productTypeParser = new ProductTypeParser({
            parametrizationParser,
            modifierSettingsParser,
            availabilitySpecParser,
            productCategories,
            modifierTypes,
            schedules,
            productTypeGroups,
            modifiers: this.catalogue.modifiers,
        });
        const productTypes = this.parseProductTypes(productTypeParser);
        const menuSectionParser = new MenuSectionParser({
            productTypes,
            customParameterTypes,
        });
        const menuSections = this.parseMenuSections(menuSectionParser);
        const menuArrangmentParser = new MenuArrangmentParser({ menuSections });
        const menuPreorderSettings = productTypeGroups.values.flatMap((groupsForType) => groupsForType.objects.mapOptional((productTypeGroup) => productTypeGroup.isVisible ? productTypeGroup.preorderSettings : null));
        return new Menu({
            productCategories,
            productTypes,
            modifierTypes,
            customParameterTypes,
            schedules,
            arrangments: this.parseMenuArragments(menuArrangmentParser),
            preorderSettings: PreorderSettings.inteligentUnion(menuPreorderSettings),
        });
    }
    parseProductTypeGroups() {
        var _a;
        const productTypeGroups = RMutableMap.empty();
        for (const [sectionId, section] of toPairs(this.catalogue.sections)) {
            if (section.content.tag !== "Products") {
                continue;
            }
            const productTypeGroup = new ProductTypeGroup({
                id: new ProductTypeGroupId(sectionId),
                preorderSettings: PreorderSettings.create({
                    timezone: this.timezone,
                    preorderSettings: Skubacz.configuration.feature_flags.preorder
                        ? (_a = section.preorderSettings) !== null && _a !== void 0 ? _a : this.onlineOrderingSettings.defaultPreorderSettings
                        : this.onlineOrderingSettings.defaultPreorderSettings,
                    orderingHours: this.orderingHours,
                }),
                availabilitySchedule: Parametrization.empty(true),
                isVisible: section.visibleIn.some((visbleIn) => visbleIn === this.appOrigin) &&
                    section.visible,
            });
            for (const [, item] of section.content.items) {
                if (item.tag === "SingleProduct") {
                    const productTypeId = new ProductTypeId(item.itemProduct);
                    productTypeGroups
                        .getOrCreate(productTypeId, () => [])
                        .push(productTypeGroup);
                }
            }
        }
        return RMap.fromIterable(productTypeGroups.entries.map(([productTypeId, groupsForType]) => [productTypeId, IdMap.fromIterable(groupsForType)]));
    }
    parseProductTypes(productTypeParser) {
        return IdMap.fromIterable(new RArray(toPairs(this.catalogue.products)).mapOptional(([id, productType]) => {
            if (!productType.visibleIn.some((visbleIn) => visbleIn === this.appOrigin)) {
                return null;
            }
            return productTypeParser.parse(id, productType);
        }));
    }
    parseMenuArragments(menuArrangmentParser) {
        return IdMap.fromIterable(toPairs(this.catalogue.menus).map(([arragmentId, arragment]) => menuArrangmentParser.parse(arragmentId, arragment)));
    }
    parseModifierTypes(modifierTypeParser) {
        return IdMap.fromIterable(toPairs(this.catalogue.modifiers).map(([id, modifier]) => modifierTypeParser.parse(id, modifier)));
    }
    parseSchedules() {
        const schedules = toPairs(this.catalogue.availabilitySchedules).map(([id, schedule]) => new Schedule({
            id: new ScheduleId(id),
            schedule: WeeklySchedule.fromWeeklyAvailability(schedule.availability),
        }));
        return IdMap.fromIterable(schedules);
    }
    parseProductCategories(productCategoryParser) {
        return IdMap.fromIterable(toPairs(this.catalogue.categories).map(([id, category]) => productCategoryParser.parse(id, category)));
    }
    parseCustomParameterTypes(customParameterTypeParser) {
        return IdMap.fromIterable(toPairs(this.catalogue.customParameters).map(([id, customParameter]) => customParameterTypeParser.parse(id, customParameter)));
    }
    parseMenuSections(menuSectionParser) {
        const sections = toPairs(this.catalogue.sections).mapOptional(([sectionId, section]) => {
            if (!section.visibleIn.some((visbleIn) => visbleIn === this.appOrigin)) {
                return null;
            }
            return menuSectionParser.parse(sectionId, section);
        });
        return IdMap.fromIterable(sections);
    }
}
