import { makeAutoObservable } from "mobx";
export class PCart {
    constructor(params) {
        this.purchaseOrder = params.purchaseOrder;
        this.restaurant = params.restaurant;
        this.orderDetails = params.orderDetails;
        this.firstOrderDiscount = params.firstOrderDiscount;
        this.fodFormController = params.fodFormController;
        this.fulfillmentFormController = params.fulfillmentFormController;
        this.fulfillmentFormReactions = params.fulfillmentFormReactions;
        this.fulfillmentTime = params.fulfillmentTime;
        this.deliveryAddress = params.deliveryAddress;
        this.geolocation = params.geolocation;
        this.orderItemListTracking = params.orderItemListTracking;
        this.crossSellingCreator = params.crossSellingCreator;
        makeAutoObservable(this);
    }
    initialize() {
        this.fulfillmentFormReactions.initialize();
        this.orderDetails.initialize();
        this.orderItemListTracking.initialize();
    }
    dispose() {
        this.fulfillmentFormReactions.dispose();
        this.orderDetails.dispose();
        this.orderItemListTracking.dispose();
    }
    // NOTICE This is used only by e2e test tool to display debug availability
    get availability() {
        return this.purchaseOrder.domain.cartAvailability;
    }
    get restaurantId() {
        return this.restaurant.id;
    }
    get restaurantName() {
        return this.restaurant.name;
    }
    get nextAvailableOrderingDate() {
        return this.purchaseOrder.nextAvailableOrderingDate;
    }
    get fodState() {
        return this.firstOrderDiscount.state;
    }
    get price() {
        return this.purchaseOrder.price;
    }
    get isReadyForCheckout() {
        return this.purchaseOrder.isReadyForCheckout;
    }
    get error() {
        return this.purchaseOrder.error;
    }
    get hasError() {
        return this.error.type !== "None";
    }
    get fulfillmentMethodsOptions() {
        return this.purchaseOrder.fulfillmentMethodOptions;
    }
    get fulfillmentMethodOptionsShort() {
        return this.purchaseOrder.fulfillmentMethodOptionsShort;
    }
    get deliveryPrice() {
        return this.purchaseOrder.deliveryPrice;
    }
    get packagingPrice() {
        return this.purchaseOrder.packagingPrice;
    }
    get orderLines() {
        return this.purchaseOrder.orderLines;
    }
    get promotions() {
        return this.orderDetails.promotionInstances;
    }
    get menuOrderOrigin() {
        return this.purchaseOrder.menuOrderOrigin;
    }
    get numItems() {
        return this.purchaseOrder.numItems;
    }
    get isEmpty() {
        return this.numItems === 0;
    }
    addOrderLines(orderLines) {
        const results = orderLines.map((orderLine) => this.purchaseOrder.addOrderLine(orderLine));
        if (results.some((result) => result === "added_new_item")) {
            this.crossSellingCreator.domain.shuffle();
        }
    }
    clear() {
        this.purchaseOrder.clear();
        this.orderDetails.clearCoupon();
    }
    get couponState() {
        return this.orderDetails.couponState;
    }
    get grandTotal() {
        return this.purchaseOrder.grandTotal;
    }
    get instanceCounts() {
        return this.purchaseOrder.instanceCounts;
    }
    get cartMeetsCouponPromotionConditions() {
        if (this.couponState.type === "Applied") {
            return this.promotions.some((promotionInstance) => promotionInstance.isCoupon);
        }
        return true;
    }
    get checkoutLoggingData() {
        return this.purchaseOrder.checkoutLoggingData;
    }
}
