import { TemporarilyUnavailableSpec } from "../Availability";
import { makeImmutable } from "../../core/makeImmutable";
/**
 * A single available choice from CustomParameterType.
 *
 * For example: "20cm" from the "size" ParameterType.
 */
export class CustomParameterChoice {
    constructor(params) {
        this.id = params.id;
        this._availabilitySpec = new TemporarilyUnavailableSpec();
    }
    get availabilitySpec() {
        return this._availabilitySpec;
    }
    // NOTICE Due to circular reference availability initialization has be defered
    setAvailability(availabilitySpec) {
        this._availabilitySpec = availabilitySpec;
        makeImmutable(this);
    }
    get customParameterTypes() {
        return this.availabilitySpec.customParameterTypes;
    }
}
