import I18n from "i18n-js";
import { makeAutoObservable } from "mobx";
import { toPairs } from "../../core";
import { CheckboxFieldController, ErrorBox } from "../../form";
export class ConsentsFormController {
    constructor(params) {
        this.consents = params.consents;
        this.tosAgreement = new CheckboxFieldController({
            initialValue: this.consents.tosAgreement,
            onUpdate: (value) => (this.consents.tosAgreement = value),
            errorBox: new ErrorBox({
                domainObjects: [this.consents],
                errorMessages: {
                    TermsOfServiceNotAccepted: I18n.t("activerecord.errors.models.user.attributes.tos_agreement.invalid"),
                },
            }),
        });
        this.subscribeEmail = new CheckboxFieldController({
            initialValue: this.consents.subscribeEmail,
            onUpdate: (value) => {
                this.consents.subscribeEmail = value;
                this.updateParentValue();
            },
        });
        this.subscribePhone = new CheckboxFieldController({
            initialValue: this.consents.subscribePhone,
            onUpdate: (value) => {
                this.consents.subscribePhone = value;
                this.updateParentValue();
            },
        });
        this.marketingConsent = new CheckboxFieldController({
            initialValue: this.consents.subscribeEmail || this.consents.subscribePhone,
            onUpdate: (value) => {
                this.subscribeEmail.change(value);
                this.subscribePhone.change(value);
            },
        });
        makeAutoObservable(this);
    }
    updateParentValue() {
        this.marketingConsent.changeWithoutUpdate(this.consents.subscribeEmail || this.consents.subscribePhone);
    }
    get fields() {
        return {
            tosAgreement: this.tosAgreement,
            marketingConsent: this.marketingConsent,
            subscribeEmail: this.subscribeEmail,
            subscribePhone: this.subscribePhone,
        };
    }
    get fieldsArray() {
        return toPairs(this.fields).map(([_, field]) => field);
    }
    get isDirty() {
        return this.fieldsArray.some((field) => field.isDirty);
    }
    get isTouched() {
        return this.fieldsArray.some((field) => field.isTouched);
    }
    get isInvalid() {
        const fieldsInvalid = this.fieldsArray.some((field) => field.isInvalid);
        const modelInvalid = this.consents.availability.isAvailable.isDefinitelyFalse;
        return fieldsInvalid || modelInvalid;
    }
    get isValid() {
        return !this.isInvalid;
    }
    get domain() {
        return this.consents;
    }
    submit() {
        this.fieldsArray.forEach((field) => field.blur());
    }
}
