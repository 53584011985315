import { makeImmutable } from "../core";
import { CrossSellingListProvider, Money, NullPackingAlgorithm, OrderingPolicy, PackingAlgorithm, ProductInstanceParser, Restaurant, automaticCrossSellingCategoryFilter, } from "../ordering";
import { AutomaticCrossSellingWeightsParser } from "./AutomaticCrossSellingWeightsParser";
import { CrossSellingListParser } from "./CrossSellingListParser";
import { MenuV2Parser } from "./MenuV2Parser";
import { PackagingContainerParser } from "./PackagingContainerParser";
export class RestaurantParser {
    constructor(appOrigin, crossSellingConsumer) {
        this.appOrigin = appOrigin;
        this.crossSellingConsumer = crossSellingConsumer;
        makeImmutable(this);
    }
    maxOrderValue(input) {
        const { max_order_value } = input;
        return max_order_value === null ? null : Money.fromBackend(max_order_value);
    }
    parse(params) {
        const { input } = params;
        if (params.checkoutInfo.fulfillmentMethods.isEmpty) {
            throw new Error("Fulfillment methods are empty");
        }
        const menuParser = new MenuV2Parser({
            catalogue: input.catalogue,
            appOrigin: this.appOrigin,
            onlineOrderingSettings: input.online_ordering_settings,
            timezone: input.time_zone,
            orderingHours: params.orderingHours,
        });
        const menu = menuParser.parseMenu();
        const minOrderValue = {
            takeaway: Money.fromString(input.min_order_value_with_pickup),
            default: Money.fromString(input.min_order_value),
        };
        const orderingPolicy = new OrderingPolicy({
            maxOrderValue: this.maxOrderValue(input),
            deliveryInTotal: input.delivery_in_total,
            enableCoupons: input.enable_coupons,
            enableFod: input.enable_fod,
            enableMarketingConsents: input.enable_marketing_consents,
        });
        const packagingContainerParser = new PackagingContainerParser({
            catalogue: input.catalogue,
        });
        const packagingContainerTypes = packagingContainerParser.parse();
        const packingAlgorithm = packagingContainerTypes.isEmpty
            ? new NullPackingAlgorithm()
            : new PackingAlgorithm({ packagingContainerTypes });
        const crossSellingListProvider = this.crossSellingListProvider({
            menu,
            catalogue: input.catalogue,
            crossSellingConsumer: this.crossSellingConsumer,
            automaticCrossSellingWeights: params.automaticCrossSellingWeights,
            manualCrossSelling: input.online_ordering_settings.manualCrossSelling,
            automaticCrossSelling: input.online_ordering_settings.automaticCrossSelling,
        });
        return new Restaurant({
            id: params.restaurantId,
            menu,
            packingAlgorithm,
            timezone: input.time_zone,
            appOrigin: this.appOrigin,
            orderingPolicy,
            minOrderValue,
            crossSellingListProvider,
            fulfillmentMethods: params.checkoutInfo.fulfillmentMethods,
            paymentMethods: params.checkoutInfo.paymentMethods,
            paymentChannels: params.paymentChannels,
            tips: input.online_ordering_settings.tips,
        });
    }
    crossSellingListProvider(params) {
        const crossSellingListParser = new CrossSellingListParser({
            catalogue: params.catalogue,
            productTypeIds: params.menu.productTypes.ids,
            productInstanceParser: new ProductInstanceParser(params.menu),
        });
        const automaticCrossSellingWeightsParser = new AutomaticCrossSellingWeightsParser({
            weights: params.automaticCrossSellingWeights,
        });
        const allowedAutomaticCrossSellingProductCategories = params.menu.productCategories.ids.filter((productCategoryId) => automaticCrossSellingCategoryFilter(params.catalogue.categories[productCategoryId.value].name));
        return CrossSellingListProvider.create({
            crossSellingConsumer: params.crossSellingConsumer,
            crossSellingListRepository: crossSellingListParser.parse(),
            menuProductTypes: params.menu.productTypes,
            allowedAutomaticProductCategories: allowedAutomaticCrossSellingProductCategories,
            automaticWeights: automaticCrossSellingWeightsParser.parse(),
            manualCrossSelling: params.manualCrossSelling,
            automaticCrossSelling: params.automaticCrossSelling,
        });
    }
}
