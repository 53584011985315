import { RMap, RMutableMap, RSet } from "../../collections";
import { makeImmutable } from "../../core/makeImmutable";
export class ParameterSetImpl {
    constructor(params) {
        this.parameterValues = params.parameterValues;
        this.valueComparator = params.valueComparator;
        makeImmutable(this);
    }
    get types() {
        return this.parameterValues.keys;
    }
    get isEmpty() {
        return this.parameterValues.isEmpty;
    }
    unify(other) {
        const matchingParameterMap = RMutableMap.empty();
        const incompatibleParameterMap = RMutableMap.empty();
        for (const [parameterTypeId, thisValue] of this.parameterValues) {
            const otherValue = other.parameterValues.find(parameterTypeId);
            if (otherValue === null) {
                continue;
            }
            if (!this.valueComparator(thisValue, otherValue)) {
                incompatibleParameterMap
                    .getOrCreate(parameterTypeId, () => [])
                    .push(otherValue);
                continue;
            }
            matchingParameterMap
                .getOrCreate(parameterTypeId, () => [])
                .push(otherValue);
        }
        const matchingParameters = matchingParameterMap
            .freeze()
            .map((v) => RSet.fromIterable(v, { ignoreDuplicates: true }));
        if (incompatibleParameterMap.isEmpty) {
            const result = new ParameterSetImpl({
                parameterValues: this.parameterValues.filterWithKey((k) => !matchingParameters.includes(k)),
                valueComparator: this.valueComparator,
            });
            return { type: "success", matchingParameters, value: result };
        }
        const incompatibleParameters = incompatibleParameterMap
            .freeze()
            .map((v) => RSet.fromIterable(v, { ignoreDuplicates: true }));
        return { type: "failure", matchingParameters, incompatibleParameters };
    }
    union(other) {
        const added = [];
        for (const [parameterTypeId, otherValue] of other.parameterValues) {
            const thisValue = this.parameterValues.find(parameterTypeId);
            if (thisValue !== null) {
                if (!this.valueComparator(thisValue, otherValue)) {
                    return null;
                }
            }
            else {
                added.push([parameterTypeId, otherValue]);
            }
        }
        return new ParameterSetImpl({
            parameterValues: this.parameterValues.union(RMap.fromIterable(added)),
            valueComparator: this.valueComparator,
        });
    }
}
