import dayjs from "./Dayjs";
import { DaySecond } from "./DaySecond";
import { makeImmutable } from "./makeImmutable";
export const WEEKDAY_NAMES = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
];
export const ISO_WEEKDAY_NAMES = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
];
/*
 * Restaumatic date wrapper. Represents Date in the restaurant timezone
 */
export class RDate {
    constructor(raw, tz) {
        this.raw = raw;
        this.tz = tz;
        makeImmutable(this);
    }
    static now(timezone) {
        return new RDate(dayjs.utc().tz(timezone), timezone);
    }
    static todayAtHour(hour, timezone) {
        return new RDate(dayjs.tz(hour, "HH:mm", timezone), timezone);
    }
    static fromString(str, timezone) {
        return new RDate(dayjs.utc(str).tz(timezone), timezone);
    }
    static fromLocalTimeString(strInLocalTime, timezone) {
        return new RDate(dayjs.tz(strInLocalTime, timezone), timezone);
    }
    get toString() {
        return this.raw.toISOString();
    }
    get weekday() {
        return WEEKDAY_NAMES[this.raw.day()];
    }
    get secondOfDay() {
        return DaySecond.fromNumber(this.differenceInSeconds(this.startOfDay()));
    }
    get isValid() {
        return this.raw.isValid();
    }
    /**
     * Format as "18:34"
     */
    get fullHour() {
        return this.raw.format("H:mm");
    }
    /**
     * Format as "YYYY-MM-DD"
     */
    get fullDate() {
        return this.raw.format("YYYY-MM-DD");
    }
    /**
     * Format with provided template
     */
    format(template) {
        return this.raw.format(template);
    }
    /**
     * Check if this date is before the provided date
     */
    isBefore(other) {
        return this.compare(other) < 0;
    }
    /**
     * Check if this date is after the provided date
     */
    isAfter(other) {
        return this.compare(other) > 0;
    }
    eq(other) {
        return this.compare(other) === 0;
    }
    compare(other) {
        return this.raw.valueOf() - other.raw.valueOf();
    }
    min(other) {
        return this.isBefore(other) ? this : other;
    }
    max(other) {
        return this.isAfter(other) ? this : other;
    }
    /**
     * Check if this date is between the provided dates
     */
    isBetween(begin, end) {
        return this.compare(begin) >= 0 && this.compare(end) <= 0;
    }
    isSameDate(other) {
        return this.raw.isSame(other.raw, "date");
    }
    get isToday() {
        return this.raw.isToday();
    }
    get isTomorrow() {
        return this.raw.isTomorrow();
    }
    differenceInSeconds(other) {
        return this.raw.diff(other.raw, "second");
    }
    add(number, unit) {
        return new RDate(this.raw.add(number, unit), this.tz);
    }
    sub(number, unit) {
        return new RDate(this.raw.subtract(number, unit), this.tz);
    }
    startOfDay() {
        return new RDate(this.raw.startOf("day"), this.tz);
    }
    endOfDay() {
        return new RDate(this.raw.endOf("day"), this.tz);
    }
    /*
     * Returns an hour indicated by the daySecond at current date
     */
    atDaySecond(daySecond) {
        const { hour, minute, second } = daySecond.hms;
        return new RDate(this.raw.set("hour", hour).set("minute", minute).set("second", second), this.tz);
    }
}
