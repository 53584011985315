import { IdMap, RMap } from "../collections";
import { makeImmutable, toPairs } from "../core";
import { Money, ProductType, ProductTypeId, } from "../ordering";
import { VolumeParser } from "./VolumeParser";
export class ProductTypeParser {
    constructor(params) {
        this.parametrizationParser = params.parametrizationParser;
        this.modifierSettingsParser = params.modifierSettingsParser;
        this.availabilitySpecParser = params.availabilitySpecParser;
        this.productCategories = params.productCategories;
        this.modifierTypes = params.modifierTypes;
        this.schedules = params.schedules;
        this.productTypeGroups = params.productTypeGroups;
        this.modifiers = params.modifiers;
        makeImmutable(this);
    }
    parse(id, productType) {
        var _a, _b;
        if (productType.availability === "Hidden") {
            return null;
        }
        const productVolume = (_a = productType.packagingContainersConfigOverride) === null || _a === void 0 ? void 0 : _a.volume;
        return new ProductType({
            id: new ProductTypeId(id),
            productCategory: this.productCategories.rawGet(productType.category),
            volume: productVolume ? VolumeParser.parse(productVolume) : null,
            pricing: this.parametrizationParser
                .parse(productType.price)
                .map((value) => Money.fromBackend(value)),
            modifierTypes: IdMap.fromIterable(productType.productModifiers.map((modifierTypeId) => this.modifierTypes.rawGet(modifierTypeId)), {
                // NOTICE Duplicated product modifiers were probably created during the migration to the menu V2
                // Please use menu analysis to diagnose if this line could be safely removed
                // (see "duplicated productModifiers" Feature in /workspace/restaumatic/apps/analyse-menu tool)
                ignoreDuplicates: true,
            }),
            groups: (_b = this.productTypeGroups.rawFind(id)) !== null && _b !== void 0 ? _b : IdMap.empty(),
            schedule: productType.availabilitySchedule === null
                ? null
                : this.schedules.rawGet(productType.availabilitySchedule),
            modifierSettings: this.parseModifierSettings(productType),
            availabilitySpec: this.parseAvailabilitySpec(productType),
        });
    }
    parseModifierSettings(productType) {
        return RMap.fromIterable(toPairs(productType.modifierSettings).mapOptional(([modifierId, settings]) => {
            const modifierType = this.modifierTypes.rawFind(modifierId);
            if (modifierType === null) {
                return null;
            }
            return [
                modifierType.id,
                this.modifierSettingsParser.parse(modifierType.itemTypeIds, modifierType.editabilityParametrization, settings),
            ];
        }));
    }
    parseAvailabilitySpec(productType) {
        return this.availabilitySpecParser.parse(productType.availability, productType.variants, productType.availabilitySchedule === null
            ? null
            : this.schedules.rawGet(productType.availabilitySchedule));
    }
}
