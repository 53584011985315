import { IdMap } from "../../collections";
import { makeImmutable } from "../../core/makeImmutable";
import { PreorderSettings } from "../PreorderSettings";
/**
 * Type of a product, such as a Margherita or Spaghetti.
 * Describes all the ways in which a certain product can configured: what modifiers can be added, etc...
 *
 * Corresponds to "Product" in MenuV2
 */
export class ProductType {
    constructor(params) {
        this.id = params.id;
        this.productCategory = params.productCategory;
        this._modifierTypes = params.modifierTypes;
        this._volume = params.volume;
        this.pricing = params.pricing;
        this.availabilitySpec = params.availabilitySpec;
        this._modifierSettings = params.modifierSettings;
        this.groups = params.groups;
        this.schedule = params.schedule;
        makeImmutable(this);
    }
    // TODO Move into the ProductTypeParser
    get preorderSettings() {
        const settings = this.groups.objects.mapOptional((productTypeGroup) => productTypeGroup.isVisible ? productTypeGroup.preorderSettings : null);
        return PreorderSettings.union(settings);
    }
    /**
     * How much volume will products of this type take up in packaging
     */
    get volume() {
        if (this._volume === null) {
            return this.productCategory.volume;
        }
        return this._volume;
    }
    /**
     * Id of the ProductCategory, to which this ProductType belongs
     */
    get productCategoryId() {
        return this.productCategory.id;
    }
    /**
     * What modifiers can be added to products of this type
     */
    get modifierTypes() {
        return this.productCategory.modifierTypes.union(this._modifierTypes);
    }
    /**
     * Returns ModifierSettings in this product for a given modifier.
     * If there are no overrides, default ModifierSettings are returned.
     */
    modifierSettings(modifierType) {
        const productSettings = this._modifierSettings.find(modifierType.id);
        if (productSettings) {
            return modifierType.defaultSettings.merge(productSettings);
        }
        else {
            return modifierType.defaultSettings;
        }
    }
    /**
     * What modifiers can be added to products of this type, partitioned by divisibility
     */
    get modifierTypesByDivisibility() {
        return this.modifierTypes.partition((modifierType) => modifierType.isDivisible);
    }
    /**
     * What divisible modifiers can be added to products of this type
     */
    get divisibleModifierTypes() {
        return this.modifierTypesByDivisibility.true;
    }
    /**
     * What non-divisible modifiers can be added to products of this type
     */
    get nonDivisibleModifierTypes() {
        return this.modifierTypesByDivisibility.false;
    }
    /**
     * @returns by which custom parameters is the price of the modifiers parametrized?
     */
    get modifierCustomParameterTypes() {
        const modifiersCustomParameterTypes = this.modifierTypes.objects.map((modifierType) => modifierType.customParameterTypes);
        const modifierSettingsCustomParameterTypes = this._modifierSettings.values.map((modifierSettings) => modifierSettings.customParameterTypes);
        return modifiersCustomParameterTypes
            .concatenated(modifierSettingsCustomParameterTypes)
            .reduce((prev, curr) => prev.union(curr), IdMap.empty());
    }
    /**
     * @returns by which custom parameters is the product parametrized?
     */
    get customParameterTypes() {
        const categoryCustomParameterTypes = this.productCategory.customParameterTypes;
        const priceCustomParameterTypes = this.pricing.customParameterTypes;
        const modifierParameterTypes = this.modifierCustomParameterTypes;
        const availabilityCustomParameterTypes = this.availabilitySpec.customParameterTypes;
        return addCustomParameterParameters(categoryCustomParameterTypes
            .union(priceCustomParameterTypes)
            .union(availabilityCustomParameterTypes)
            .union(modifierParameterTypes));
    }
    /**
     * @returns by which custom parameters, grouped into divisible and non-divisible, is the price of the product parametrized?
     */
    get customParameterTypesByDivisibility() {
        return this.customParameterTypes.partition((customParameterType) => customParameterType.isDivisible);
    }
    /**
     * @returns by which divisible custom parameters is the price of the product parametrized?
     */
    get divisibleCustomParameterTypes() {
        return this.customParameterTypesByDivisibility.true;
    }
    /**
     * @returns by which non-divisible custom parameters is the price of the product parametrized?
     */
    get nonDivisibleCustomParameterTypes() {
        return this.customParameterTypesByDivisibility.false;
    }
}
/**
 * Extends the map by custom parameters (transitively) required by the parameters themselves.
 */
function addCustomParameterParameters(m) {
    const visited = new Map();
    function go(p) {
        if (visited.has(p.id.associationKey)) {
            return;
        }
        visited.set(p.id.associationKey, p);
        for (const p2 of p.customParameterTypes.objects) {
            go(p2);
        }
    }
    for (const p of m.objects) {
        go(p);
    }
    return IdMap.fromIterable(visited.values());
}
