import I18n from "i18n-js";
export const PCartError = {
    none() {
        return new None();
    },
    minOrderValueWarning(minValue, missingValue) {
        return new MinOrderValueWarning(minValue, missingValue);
    },
    maxOrderValueWarning(maxValue) {
        return new MaxOrderValueWarning(maxValue);
    },
    unavailableDishes(names) {
        return new UnavailableDishes(names);
    },
    orderingNotPossible() {
        return new OrderingNotPossible();
    },
    notOverlappingDishes() {
        return new NotOverlappingDishes();
    },
    productNotAvailable() {
        return new ProductNotAvailable();
    },
};
class None {
    constructor() {
        this.type = "None";
        this.level = "danger";
        this.message = null;
    }
}
class MinOrderValueWarning {
    constructor(minValue, missingValue) {
        this.type = "MinOrderValueWarning";
        this.level = "danger";
        this.message = I18n.t("activerecord.errors.models.order.min_order_value", {
            value: minValue,
            missing_value: missingValue,
        });
    }
}
class MaxOrderValueWarning {
    constructor(maxValue) {
        this.type = "MaxOrderValueWarning";
        this.level = "danger";
        this.message = I18n.t("activerecord.errors.models.order.max_order_value", {
            value: maxValue,
        });
    }
}
class UnavailableDishes {
    constructor(names) {
        this.type = "UnavailableDishes";
        this.level = "danger";
        this.message = `${I18n.t("restaurants.widgets.cart.unavailable")} (${names})`;
    }
}
class OrderingNotPossible {
    constructor() {
        this.type = "OrderingNotPossible";
        this.level = "danger";
        this.message = I18n.t("restaurants.menu.closed_message");
    }
}
class NotOverlappingDishes {
    constructor() {
        this.type = "NotOverlappingDishes";
        this.level = "danger";
        this.message = I18n.t("orders.form.not_overlapping_delivery_times");
    }
}
class ProductNotAvailable {
    constructor() {
        this.type = "ProductNotAvailable";
        this.level = "danger";
        this.message = I18n.t("orders.form.cart_product_not_available");
    }
}
