import { makeAutoObservable, runInAction } from "mobx";
import { ErrorBox } from "./ErrorBox";
export class SelectableFieldController {
    constructor(params) {
        var _a, _b;
        this._value = params.initialValue;
        this._isDirty = false;
        this._isTouched = false;
        this.onUpdate = params.onUpdate;
        this.onClear = params.onClear;
        this.onBlur = params.onBlur;
        this.errorBox = (_a = params.errorBox) !== null && _a !== void 0 ? _a : new ErrorBox({ errorMessages: {} });
        this.options = (_b = params.options) !== null && _b !== void 0 ? _b : [];
        makeAutoObservable(this);
    }
    get value() {
        return this._value;
    }
    get isDirty() {
        return this._isDirty;
    }
    get isTouched() {
        return this._isTouched;
    }
    get isValid() {
        return this.errorBox.isEmpty;
    }
    get isInvalid() {
        return !this.errorBox.isEmpty;
    }
    get errors() {
        return this.errorBox.errors;
    }
    blur() {
        this._isTouched = true;
        if (this.onBlur !== undefined) {
            this.onBlur();
        }
    }
    clear() {
        this._value = null;
        this._isDirty = false;
        this._isTouched = false;
        runInAction(() => this.onClear());
    }
    change(value) {
        this._value = value;
        this._isDirty = true;
        runInAction(() => this.onUpdate(value));
    }
}
