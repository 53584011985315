import { ScheduleAvailability } from "../..";
import { PreorderSettingsAvailability } from "../ProductInstanceParts/PreorderSettingsAvailability";
import { AsyncAvailability } from "./AsyncAvailability";
import { Available } from "./Available";
import { BooleanAvailability } from "./BooleanAvailability";
import { CompositeAvailability } from "./CompositeAvailability";
import { DependentAvailability } from "./DependentAvailability";
import { Maybe } from "./Maybe";
import { ParametrizedAvailability } from "./ParametrizedAvailability";
import { Unavailable } from "./Unavailable";
/*
 * Collection of various domain objects availability calculation strategies
 */
export const Availability = {
    available() {
        return new Available();
    },
    unavailable(reason) {
        return new Unavailable(reason);
    },
    composite(childAvailabilities) {
        return new CompositeAvailability(childAvailabilities);
    },
    boolean(booleanMap) {
        return new BooleanAvailability(booleanMap);
    },
    dependent(reason, description, dependency) {
        return new DependentAvailability({ reason, description, dependency });
    },
    ofProductType(productType, scope) {
        const { availabilitySpec, pricing } = productType;
        if (availabilitySpec.type === "TemporarilyUnavailable") {
            return new Unavailable("TemporarilyUnavailable");
        }
        const parametric = new ParametrizedAvailability({
            unavailabilityReason: "ParametrizedAvailability",
            parametrization: availabilitySpec.availabilityParametrization,
            scope,
        });
        const schedule = availabilitySpec.availabilitySchedule === null
            ? Availability.available()
            : new ScheduleAvailability({
                unavailabilityReason: "Schedule",
                availabilitySchedule: availabilitySpec.availabilitySchedule,
                fulfillmentTime: scope.fulfillmentTime,
            });
        const price = new ParametrizedAvailability({
            unavailabilityReason: "PriceAvailability",
            parametrization: pricing.map(() => true),
            scope,
        });
        const preorder = new PreorderSettingsAvailability({
            scope,
            preorderSettings: productType.preorderSettings,
        });
        const visibility = productType.groups.objects.some((productTypeGroup) => productTypeGroup.isVisible)
            ? Availability.available()
            : Availability.unavailable("Visibility");
        return new CompositeAvailability([
            parametric,
            schedule,
            price,
            preorder,
            visibility,
        ]);
    },
    ofModifierType(modifierType, scope) {
        return new ParametrizedAvailability({
            unavailabilityReason: "ParametrizedAvailability",
            parametrization: modifierType.availabilityParametrization,
            scope,
        });
    },
    ofModifierItemType(modifierItemType, scope) {
        const { availabilitySpec, pricing } = modifierItemType;
        switch (availabilitySpec.type) {
            case "TemporarilyUnavailable":
                return new Unavailable("TemporarilyUnavailable");
            case "HiddenModifierItem":
                return new Unavailable("Hidden");
        }
        const parametric = new ParametrizedAvailability({
            unavailabilityReason: "ParametrizedAvailability",
            parametrization: availabilitySpec.availabilityParametrization,
            scope,
        });
        const price = new ParametrizedAvailability({
            unavailabilityReason: "PriceAvailability",
            parametrization: pricing.map(() => true),
            scope,
        });
        return new CompositeAvailability([parametric, price]);
    },
    ofCustomParameterChoice(customParameterChoice, scope) {
        const { availabilitySpec } = customParameterChoice;
        if (availabilitySpec.type === "TemporarilyUnavailable") {
            return new Unavailable("TemporarilyUnavailable");
        }
        return new ParametrizedAvailability({
            unavailabilityReason: "ParametrizedAvailability",
            parametrization: availabilitySpec.availabilityParametrization,
            scope,
        });
    },
    // NOTICE: async is not used already (removed from Restaurant's orderingAvailability)
    async(promise, pendingUnavailability) {
        return new AsyncAvailability({ promise, pendingUnavailability });
    },
    halving(halvingPolicy, scope) {
        return new ParametrizedAvailability({
            unavailabilityReason: "HalvingAvailability",
            parametrization: halvingPolicy.halvingAvailability,
            scope,
        });
    },
    ofDateRange(period, scope, options) {
        var _a;
        const { fulfillmentTime, now } = scope;
        if (period.end.isBefore(now)) {
            return new Unavailable("ExpiredDateRange");
        }
        const checkIfNowIsInRange = (_a = options === null || options === void 0 ? void 0 : options.checkIfNowIsInRange) !== null && _a !== void 0 ? _a : false;
        if (checkIfNowIsInRange && !period.includes(now)) {
            return new Unavailable("NowOutsideRange");
        }
        if (fulfillmentTime === null) {
            return new Maybe("DateNotSelected");
        }
        if (period.includes(fulfillmentTime)) {
            return new Available();
        }
        return new Unavailable("DateOutsideRange");
    },
    promotionWeeklySchedule(schedule, scope) {
        const { fulfillmentTime, now } = scope;
        if (!schedule.includes(now)) {
            return new Unavailable("NowOutsideSchedule");
        }
        if (fulfillmentTime === null) {
            return new Maybe("DateNotSelected");
        }
        if (schedule.includes(fulfillmentTime)) {
            return new Available();
        }
        return new Unavailable("DateOutsideSchedule");
    },
    ofPromotionType(promotionType, scope) {
        const parametrized = new ParametrizedAvailability({
            unavailabilityReason: "ParametrizedAvailability",
            parametrization: promotionType.availabilityParametrization,
            scope,
        });
        const debugId = `PromotionType#${promotionType.id.value}`;
        const dateRange = Availability.ofDateRange(promotionType.period, scope, {
            checkIfNowIsInRange: true,
        });
        const schedule = Availability.promotionWeeklySchedule(promotionType.availabilitySchedule, scope);
        return Availability.composite([
            Availability.dependent("DateRange", debugId, dateRange),
            Availability.dependent("Schedule", debugId, schedule),
            parametrized,
        ]);
    },
};
