import { makeImmutable } from "../core/makeImmutable";
export class PMenuSection {
    constructor(params) {
        this.domain = params.domain;
        this._name = params.name;
        this.photoUrl = params.photoUrl;
        this.items = params.items;
        this.visibilitySchedule = params.visibilitySchedule;
        makeImmutable(this);
    }
    get id() {
        return this.domain.id;
    }
    get name() {
        return this._name.value;
    }
    get isVisible() {
        // TODO: implement schedule visibility
        return true;
    }
}
