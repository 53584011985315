import { RArray } from "..";
import DebugAvailablityVistor from "../ordering/Availability/DebugAvailablityVistor";
export function debugAvailability(availability) {
    const reasons = new RArray(availability.unavailabilityReasons).sorted((a, b) => a.localeCompare(b)).raw;
    const rootVistor = new DebugAvailablityVistor();
    availability.accept(rootVistor);
    return {
        reasons,
        errors: rootVistor.errors,
    };
}
