import { IdMap, RArray, RSet } from "../../collections";
import { associationKey } from "../../core";
import { makeImmutable } from "../../core/makeImmutable";
import { ParameterMultiSet } from "../ParameterMultiSet";
import { ParameterCombination, } from "./ParameterCombination";
/**
 * A parametrization of a thing. A thing can be parametric, which means its value is dependent on other values (parameters).
 *
 * For example: a price of a "pizza" can depend on the "pizza size" and "pizza dough type" parameters, so that for any combination of "size" and "dough type", a price will be different.
 *
 *
 * size: 20cm, dough: thick -> value: 24zł,
 *
 * size: 30cm, dough: thick -> value: 31zł,
 *
 * size: 20cm, dough: thin  -> value: 22zł,
 *
 * etc...
 *
 *
 * WARNING: A Parametrization can never be assumed to have only one concrete value (can not be considered fully applied)!!!
 *
 * To get a value from a parametric, you have to use `.values()` and consider all possible values. See: `Price.spectrum` for example.
 */
export class Parametrization {
    constructor(params) {
        this.state = params.state;
        this.parameterCombinations = params.parameterCombinations;
        this.matchingParameters = params.matchingParameters;
        this.incompatibleParameters = params.incompatibleParameters;
        makeImmutable(this);
    }
    static fromParameterCombinations(parameterCombinations) {
        return new Parametrization({
            state: "Raw",
            parameterCombinations,
            matchingParameters: ParameterMultiSet.empty(),
            incompatibleParameters: ParameterMultiSet.empty(),
        });
    }
    get customParameterTypes() {
        return this.parameterCombinations
            .map((parameterCombination) => parameterCombination.customParameterTypes)
            .reduce((prev, curr) => prev.union(curr), IdMap.empty());
    }
    static empty(value) {
        return Parametrization.fromParameterCombinations(RArray.singleton(ParameterCombination.empty(value)));
    }
    map(mapping) {
        const mappedParameterCombinations = this.parameterCombinations.map((parameterCombination) => parameterCombination.map(mapping));
        return new Parametrization({
            state: this.state,
            parameterCombinations: mappedParameterCombinations,
            incompatibleParameters: this.incompatibleParameters,
            matchingParameters: this.matchingParameters,
        });
    }
    /**
     * Get all possible values of the parametric
     */
    get values() {
        return RSet.fromIterable(this.parameterCombinations.map(({ value }) => value), { ignoreDuplicates: true });
    }
    get hasPossibleValues() {
        return !this.parameterCombinations.isEmpty;
    }
    /**
     * A Parametrization can be "Raw" or "Applied"
     *
     * A raw Parametrization includes all parameter combinations as pulled from backend
     *
     * Unification prunes some of the parameter combinations
     *
     * For example: unifying a set where `size: 20cm`, will exclude all combinations where `size` is not `20cm`
     *
     * Warning: A Parametrization can never be assumed to be fully unified!!!
     *
     * @returns Unified parametrization
     */
    unify(parameters) {
        const unified = this.parameterCombinations.map((parameterCombination) => parameterCombination.unify(parameters));
        const successes = unified.mapOptional((result) => result.type === "success" ? result.value : null);
        const failures = unified.mapOptional((result) => result.type === "failure" ? result.incompatibleParameters : null);
        return new Parametrization({
            state: "Applied",
            parameterCombinations: successes,
            matchingParameters: unified.reduce((acc, el) => acc.union(el.matchingParameters), ParameterMultiSet.empty()),
            incompatibleParameters: failures.reduce((acc, el) => acc.union(el), ParameterMultiSet.empty()),
        });
    }
    /**
     * Combine two parametrizations and apply the provided functions to its values
     */
    combine(other, combinationFn) {
        const resultParameterCombinations = [];
        for (const thisCombination of this.parameterCombinations) {
            for (const otherCombination of other.parameterCombinations) {
                const resultCombination = thisCombination.combine(otherCombination, combinationFn);
                if (resultCombination !== null) {
                    resultParameterCombinations.push(resultCombination);
                }
            }
        }
        return new Parametrization({
            state: this.state,
            parameterCombinations: new RArray(resultParameterCombinations),
            incompatibleParameters: this.incompatibleParameters.union(other.incompatibleParameters),
            // TODO This is naive algorithm, look deeper into it
            matchingParameters: this.matchingParameters.intersection(other.matchingParameters),
        });
    }
    /**
     * Can this Parametrization have the given value for some combination of parameters?
     */
    isValuePossible(value) {
        const valueKey = associationKey(value);
        return this.parameterCombinations.some((combination) => valueKey === associationKey(combination.value));
    }
    toDebugJSON() {
        return this.parameterCombinations.raw.map((combination) => [
            combination.premise.toDebugJSON(),
            combination.value,
        ]);
    }
}
