import { makeAutoObservable } from "mobx";
import { ASAPFulfillmentTime, Availability, } from "../../ordering";
import I18n from "i18n-js";
export class PAsapFulfillmentTime {
    constructor(params) {
        this.type = "ASAP";
        this.purchaseOrderFulfillmentTime = params.purchaseOrderFulfillmentTime;
        this.label = I18n.t("orders.form.asap");
        makeAutoObservable(this);
    }
    get isAvailable() {
        return this.purchaseOrderFulfillmentTime.asapOptionAvailable;
    }
    get domainValue() {
        return new ASAPFulfillmentTime();
    }
    get availability() {
        return Availability.available();
    }
}
