import BsModal from "bootstrap/js/dist/modal";
const WHATSAPP_MODAL_ID = "whatsapp_modal";
const WHATSAPP_MODAL_SESSION_KEY = "feature_whatsapp_modal";
const modalTemplate = (url) => `
<div class="modal fade" id="${WHATSAPP_MODAL_ID}" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header u-border-none u-bg-color-inherit">
        <button type="button" class="close" data-bs-dismiss="modal" aria-label="Zamknij">
          <span aria-hidden="true" class="icon-cancel"></span>
        </button>
        <h4 class="modal-title">Zamów przez WhatsApp</h4>
      </div>
      <div class="modal-body">
        Złoż zamówienie wygodnie i szybko, korzystając z komunikatora.
      </div>
      <div class="modal-footer u-border-none">
        <a class="btn btn-primary btn-block" href="${url}">
          <i class="icon-whatsapp icon-btn u-font-size-120p u-mr1" aria-hidden="true"></i>
          <span>Zamów na WhatsApp</span>
        </a>
      </div>
    </div>
  </div>
</div>
`;
const buttonTemplate = (url, btnClasses) => `
<br>
<a class="${btnClasses} u-small-spacing-y js-skip" href="${url}" style="padding-inline: 24px;">
  <i class="icon-whatsapp icon-btn u-font-size-120p u-mr1" aria-hidden="true"></i>
  <span>Zamów na WhatsApp</span>
</a>
`;
function addWhatsAppButtons(url) {
    // INFO: At this moment we want to start experiment on vesspa / fiesta / new vision themes
    const buttonSiblingsSelectors = [
        ".m-hero .m-slider__action .btn",
        ".m-footer .row--flex .u-small-spacing-b .btn-primary",
        ".m-header .m-hero__action .btn-primary-on-dark",
        ".m-header .m-menu-hero .m-menu-hero__action .btn-primary-on-dark",
        ".m-footer .m-footer__content .btn-primary:not(.m-icon-group__item)",
        ".m-header .m-hero .m-hero__action .btn-primary", // New Vision hero
    ];
    buttonSiblingsSelectors.forEach((selector) => {
        const buttonElements = document.querySelectorAll(selector);
        buttonElements.forEach((button) => {
            if (!button.classList.contains("js-skip")) {
                button.insertAdjacentHTML("afterend", buttonTemplate(url, button.classList.value));
                button.classList.add("js-skip");
            }
        });
    });
}
function triggerModalOnScroll() {
    const modalEl = document.getElementById(WHATSAPP_MODAL_ID);
    const isModalShown = sessionStorage.getItem(WHATSAPP_MODAL_SESSION_KEY) === "shown";
    let hasScrolled = false;
    if (!hasScrolled) {
        hasScrolled = true;
        if (modalEl && !isModalShown) {
            const modalObj = BsModal.getOrCreateInstance(modalEl);
            setTimeout(() => {
                modalObj.show();
                sessionStorage.setItem(WHATSAPP_MODAL_SESSION_KEY, "shown");
            }, 800);
        }
        window.removeEventListener("scroll", triggerModalOnScroll);
    }
}
function addWhatsAppModal(url) {
    document.body.insertAdjacentHTML("beforeend", modalTemplate(url));
    window.addEventListener("scroll", triggerModalOnScroll);
}
function initWhatsAppExperiment() {
    const whatsappUrl = Skubacz.configuration.whatsapp_url;
    const isCheckout = Skubacz.configuration.view_name === "checkout";
    const isProductCard = window.location.href.includes("#product");
    const isCart = window.location.href.includes("#modal-cart");
    if (whatsappUrl &&
        whatsappUrl.length !== 0 &&
        !isCheckout &&
        !isProductCard &&
        !isCart) {
        addWhatsAppButtons(whatsappUrl);
        addWhatsAppModal(whatsappUrl);
    }
}
export default {
    init: initWhatsAppExperiment,
};
