import { DateRange, DysjointDateRanges, makeImmutable, } from "../../core";
export class RelativeDatePreorderSetting {
    constructor(maxDays) {
        this.type = "RelativeDate";
        this.maxDays = maxDays;
        makeImmutable(this);
    }
    max(other) {
        return new RelativeDatePreorderSetting(Math.max(this.maxDays, other.maxDays));
    }
    dateRanges(now) {
        const range = DateRange.fromDates({
            begin: now,
            end: now.add(this.maxDays, "days").endOfDay(),
        });
        return DysjointDateRanges.singleton(range);
    }
}
