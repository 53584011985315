import { makeAutoObservable } from "mobx";
import { ParameterScope } from "../ParameterScope";
import { ProductInstancePart } from "../ProductInstancePart";
import { ProductConfigCreator } from "./ProductConfigCreator";
/*
 * Utility class for building correct `ProductInstancePart` - it deals with changing productTypes in halves
 */
export class ProductInstanceCreatorPart {
    constructor(params) {
        this._scope = ParameterScope.Uninitialized;
        this.productType = params.productType;
        this.productConfigCreator = params.productConfigCreator;
        makeAutoObservable(this);
    }
    get productTypeId() {
        return this.productType.id;
    }
    get scope() {
        return this._scope;
    }
    static create(part) {
        const { customParameterInstances: initialCustomParameterInstances, modifierInstances: initialModifierInstances, } = part.productConfig;
        const productConfigCreator = ProductConfigCreator.create({
            modifierTypes: part.productType.divisibleModifierTypes,
            customParameterTypes: part.productType.divisibleCustomParameterTypes,
            initialCustomParameterInstances,
            initialModifierInstances,
            modifierSettings: (modifier) => part.productType.modifierSettings(modifier),
        });
        return new ProductInstanceCreatorPart({
            productType: part.productType,
            productConfigCreator,
        });
    }
    initWithScope(parentScope) {
        this.productConfigCreator.initWithScope(parentScope);
        this._scope = this.productConfigCreator.scope;
    }
    get productInstancePart() {
        return new ProductInstancePart({
            scope: this.scope,
            productType: this.productType,
            productConfig: this.productConfigCreator.productConfig,
        });
    }
    get availability() {
        return this.productInstancePart.availability;
    }
    clone() {
        return new ProductInstanceCreatorPart({
            productType: this.productType,
            productConfigCreator: this.productConfigCreator.clone(),
        });
    }
    /** See {@link ProductInstanceCreator.fixAvailability} */
    fixAvailability() {
        this.productConfigCreator.fixAvailability();
    }
    selectInferredDefaults() {
        this.productConfigCreator.selectInferredDefaults();
    }
}
