import { makeAutoObservable } from "mobx";
import { makeImmutable } from "../core";
import { MoneySpectrum } from "../pricing/Price";
import { PPrice } from "./PPrice";
export class PMenuSectionItemSingleProductContent {
    constructor(params) {
        this.type = "SingleProduct";
        this._name = params.name;
        this.variants = params.variants;
        this.columns = params.columns;
        this.availabilitySchedule = params.availabilitySchedule;
        this.localizationProvider = params.localizationProvider;
        makeAutoObservable(this);
    }
    get name() {
        return this._name.value;
    }
    get aggregatedPrice() {
        const aggregated = this.variants
            .map((variant) => variant.price.spectrum)
            .reduce((prev, curr) => MoneySpectrum.union(prev, curr), {
            type: "null",
        });
        return new PPrice(this.localizationProvider, aggregated);
    }
    get dropdown() {
        switch (this.variants.length) {
            case 0:
                return null;
            case 1:
                return { type: "SingleDropdown", item: this.variants[0] };
            default:
                return {
                    type: "VariantsDropdown",
                    price: this.aggregatedPrice,
                    isAvailable: this.variants.some((variant) => variant.isAvailable),
                    items: this.variants,
                };
        }
    }
}
export class PMenuSectionItemCreatorContent {
    constructor(variant) {
        this.type = "Creator";
        this.variant = variant;
        makeAutoObservable(this);
    }
}
export class PMenuSectionItem {
    constructor(params) {
        this.id = params.id;
        this.content = params.content;
        makeImmutable(this);
    }
}
