import I18n from "i18n-js";
export const PProductError = {
    none() {
        return new None();
    },
    unavailable(details) {
        return new Unavailable(details);
    },
    temporarilyUnavailable() {
        return new TemporarilyUnavailable();
    },
    maxItemsExceeded(maxItemsNumber) {
        return new MaxItemsExceeded(maxItemsNumber.value);
    },
    notEnoughItems(minItemsNumber) {
        return new NotEnoughItems(minItemsNumber.value);
    },
    notExpectedItems(expectedItemsNumber) {
        return new NotExpectedItems(expectedItemsNumber.value);
    },
    notDivisible(details) {
        return new NotDivisible(details);
    },
    required() {
        return new Required();
    },
};
class None {
    constructor() {
        this.type = "None";
        this.level = "warning";
        this.message = null;
    }
}
class Unavailable {
    constructor(details) {
        this.type = "Unavailable";
        this.level = "warning";
        this.message = `${I18n.t("_core_types_sites.menu.creator.errors.productUnavailableInThisVariant")}${details === null ? "" : ` (${details})`}`;
    }
}
class TemporarilyUnavailable {
    constructor() {
        this.type = "TemporarilyUnavailable";
        this.level = "danger";
        this.message = I18n.t("_core_types_sites.menu.creator.errors.productTemporarilyUnavailable");
    }
}
class MaxItemsExceeded {
    constructor(maxItems) {
        this.type = "MaxItemsExceeded";
        this.level = "danger";
        this.maxItems = maxItems;
        this.message = I18n.t("_core_types_sites.menu.creator.errors.chooseAtMostNModifierItems", { maxItems });
    }
}
class NotEnoughItems {
    constructor(minItems) {
        this.type = "NotEnoughItems";
        this.level = "danger";
        this.minItems = minItems;
        this.message = I18n.t("_core_types_sites.menu.creator.errors.chooseAtLeastNModifierItems", { minItems });
    }
}
class NotExpectedItems {
    constructor(expectedItems) {
        this.type = "NotExpectedItems";
        this.level = "danger";
        this.expectedItems = expectedItems;
        this.message = I18n.t("_core_types_sites.menu.creator.errors.chooseExactlyNModifierItems", { expectedItems });
    }
}
class NotDivisible {
    constructor(details) {
        this.type = "NotDivisible";
        this.level = "danger";
        this.message = `${I18n.t("_core_types_sites.menu.creator.errors.productNotDivisibleInThisVariant")} (${details})`;
    }
}
class Required {
    constructor() {
        this.type = "Required";
        this.level = "danger";
        this.message = I18n.t("_common.form.errors.required");
    }
}
