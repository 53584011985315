import { makeAutoObservable } from "mobx";
import { Email } from "../../form";
import { Availability } from "../Availability";
/**
 * Contact details needed for a QRWaiter order.
 */
export class QrWaiterContactDetails {
    constructor(params) {
        this.type = "QRWaiter";
        this.contactDetailsLock = params.contactDetailsLock;
        this._email = params.email;
        makeAutoObservable(this);
    }
    static empty(contactDetailsLock) {
        return new QrWaiterContactDetails({
            contactDetailsLock,
            email: new Email(""),
        });
    }
    static fromStorageData(contactDetailsLock, storageData) {
        return new QrWaiterContactDetails({
            contactDetailsLock,
            email: new Email(storageData.email),
        });
    }
    setData(data) {
        if (this.locked)
            return;
        if (data.email !== undefined) {
            this.email = data.email;
        }
    }
    set email(value) {
        if (this.locked) {
            throw Error("Can't change email, ContactDetails locked");
        }
        this._email = value;
    }
    get email() {
        return this._email;
    }
    get fullName() {
        throw new Error("Can't access fullName at QRWaiterContactDetails");
    }
    set fullName(_) {
        throw new Error("Can't set fullName at QRWaiterContactDetails");
    }
    get phone() {
        throw new Error("Can't access phone at QRWaiterContactDetails");
    }
    set phone(_) {
        throw new Error("Can't set phone at QRWaiterContactDetails");
    }
    setPhone(_) {
        throw new Error("Can't set phone at QRWaiterContactDetails");
    }
    get locked() {
        return this.contactDetailsLock.locked;
    }
    unlock() {
        this.contactDetailsLock.unlock();
    }
    get availability() {
        return Availability.composite([this.email.availability]);
    }
    /**
     * @returns data to be sent to the backend
     */
    get checkoutData() {
        var _a;
        return {
            email: (_a = this.email.value) !== null && _a !== void 0 ? _a : null,
            phone: "",
            fullName: "",
        };
    }
    get storageData() {
        return {
            type: "QRWaiter",
            email: this.email.value,
        };
    }
}
