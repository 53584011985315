import { PQrWaiterFulfillmentTime } from "./PQrWaiterFulfillmentTime";
import { POnlineFulfillmentTime } from "./POnlineFulfillmentTime";
export const PFulfillmentTime = {
    create(params) {
        if (params.purchaseOrderFulfillmentTime.type === "Online") {
            return new POnlineFulfillmentTime({
                timezone: params.timezone,
                purchaseOrderFulfillmentTime: params.purchaseOrderFulfillmentTime,
            });
        }
        else {
            return new PQrWaiterFulfillmentTime({
                fulfillmentInstructions: params.fulfillmentInstructions,
            });
        }
    },
};
