import { RArray } from "../../collections";
export class QrWaiterOrderingHours {
    fulfillmentTimes(date) {
        return RArray.empty();
    }
    endOfDay(date) {
        return date.add(1, "day");
    }
    nextEventAtDate(after) {
        return { type: "OrderingEnds", at: null };
    }
}
