import { RSet } from "../../collections";
import { makeImmutable } from "../../core";
export class InternalParameterMultiSet {
    constructor(params) {
        this.fulfillmentMethod = params.fulfillmentMethod;
        this.splitToHalves = params.splitToHalves;
        this.orderOrigin = params.orderOrigin;
        makeImmutable(this);
    }
    get isEmpty() {
        return (this.fulfillmentMethod.isEmpty &&
            this.splitToHalves.isEmpty &&
            this.orderOrigin.isEmpty);
    }
    static empty() {
        return new InternalParameterMultiSet({
            fulfillmentMethod: RSet.empty(),
            splitToHalves: RSet.empty(),
            orderOrigin: RSet.empty(),
        });
    }
    union(other) {
        return new InternalParameterMultiSet({
            fulfillmentMethod: this.fulfillmentMethod.union(other.fulfillmentMethod),
            splitToHalves: this.splitToHalves.union(other.splitToHalves),
            orderOrigin: this.orderOrigin.union(other.orderOrigin),
        });
    }
    intersection(other) {
        return new InternalParameterMultiSet({
            fulfillmentMethod: this.fulfillmentMethod.intersection(other.fulfillmentMethod),
            splitToHalves: this.splitToHalves.intersection(other.splitToHalves),
            orderOrigin: this.orderOrigin.intersection(other.orderOrigin),
        });
    }
}
