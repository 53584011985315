import { makeImmutable } from "../../core";
import { PMenuSection } from "../../presentation";
import { IdMap, RArray } from "../../collections";
export class PMenuSectionParser {
    constructor(params) {
        this.domainMenuSections = params.domainMenuSections;
        this.domainSchedules = params.domainSchedules;
        this.translatedParser = params.translatedParser;
        this.pMenuSectionItemParser = params.pMenuSectionItemParser;
        makeImmutable(this);
    }
    parse(id, section) {
        const scheduleId = section.visibilitySchedule;
        const domainSection = this.domainMenuSections.rawFind(id);
        if (!domainSection) {
            return null;
        }
        return new PMenuSection({
            domain: domainSection,
            name: this.translatedParser.parse(section.name),
            // TODO: do we want to process it here (resize etc.)?
            photoUrl: section.photo,
            items: this.parseSectionItems(domainSection, section),
            visibilitySchedule: scheduleId === null ? null : this.domainSchedules.rawGet(scheduleId),
        });
    }
    parseSectionItems(domainSection, section) {
        if (section.content.tag !== "Products") {
            return IdMap.empty();
        }
        const sectionItems = new RArray(section.content.items).mapOptional(([itemId, item]) => this.pMenuSectionItemParser.parse({
            domainSection,
            section,
            id: itemId,
            item,
        }));
        return IdMap.fromIterable(sectionItems);
    }
}
