import StorageService from "../services/StorageService";
import getMainElement from "./getMainElement";
import { scrollToElement } from "./Scroll";
/**
 * Restaurant slug prefix in the hash.
 *
 * URL format should be excluded in ScrollSpy target selector (`#/r=restaurant` is not a valid ID attribute).
 */
const hashSlugPrefix = "/r=";
/**
 * Array with view names that should have URL hash links.
 *
 * Include only pages which the main content varies by restaurant.
 */
const pagesWithUrlHashLinks = [
    "contact",
    "gallery",
    "reservation",
    "promotions",
    "reviews",
    "privacy_policy",
    "terms",
];
/**
 * Check if current view has many restaurants.
 */
const isMultiRestaurantPage = !Skubacz.configuration.restaurant_slug &&
    pagesWithUrlHashLinks.indexOf(Skubacz.configuration.view_name) !== -1;
/**
 * Check if target link is on the same page.
 */
const isSelfLink = (url) => window.location.href.indexOf(url.split("#")[0]) !== -1;
/**
 * Check if URL contains hash with restaurant slug.
 */
const isRestaurantSlugInUrl = (url = window.location.hash) => url.indexOf(`#${hashSlugPrefix}`) != -1;
/**
 * Get restaurant slug from URL.
 */
const getRestaurantSlugFromUrl = (url = window.location.hash) => isRestaurantSlugInUrl(url)
    ? url.split(`#${hashSlugPrefix}`)[1].split("/")[0].split("#")[0]
    : "";
/**
 * Get restaurant slug specific from Menu Page URL.
 *
 * TODO: refactor after change static part of menu page url "/restauracja/"
 */
const getRestaurantSlugFromMenuPageUrl = (url = window.location.pathname) => {
    const isPageMenu = Skubacz.configuration.view_name === "menu";
    const menuPageUrlSlugPrefix = "/restauracja/";
    return isPageMenu ? url.split(`${menuPageUrlSlugPrefix}`)[1] : "";
};
/**
 * Get slug of the current restaurant.
 *
 * @returns {string} Restaurant slug.
 */
export const getCurrentRestaurantSlug = () => StorageService.getItem("currentRestaurant");
/**
 * Save current restaurant in localStorage to use in other components.
 *
 * @param slug {string} Restaurant slug.
 */
const setRestaurant = (slug) => StorageService.setItem("currentRestaurant", slug);
/**
 * Select the restaurant by slug.
 *
 * @param slug {string} Restaurant slug.
 * @param updateHistory {boolean} True if browser history should be updated with URL hash (only on multiple restaurant pages).
 */
function selectRestaurant(slug, updateHistory = true) {
    const elementsToShow = document.querySelectorAll(`[data-restaurant-visible='${slug}']`);
    if (elementsToShow.length) {
        const currentRestaurantSelects = document.querySelectorAll(".js-current-restaurant-select");
        currentRestaurantSelects.forEach((select) => (select.value = slug));
        document
            .querySelectorAll("[data-restaurant-visible]")
            .forEach((el) => el.classList.add("hidden"));
        elementsToShow.forEach((el) => el.classList.remove("hidden"));
    }
    if (slug != getCurrentRestaurantSlug()) {
        setRestaurant(slug);
        const event = new CustomEvent("change-restaurant.restaumatic", {
            detail: {
                slug: slug,
            },
        });
        document.dispatchEvent(event);
    }
    if (isMultiRestaurantPage && updateHistory) {
        history.pushState({}, "", "#" + hashSlugPrefix + slug);
    }
}
/**
 * Attach event handlers.
 */
function attachEvents() {
    document.querySelectorAll(".js-current-restaurant-select").forEach((el) => {
        el.addEventListener("change", (e) => selectRestaurant(e.currentTarget.value));
    });
    document.querySelectorAll(".js-current-restaurant-link").forEach((el) => {
        el.addEventListener("click", (e) => {
            const link = e.currentTarget;
            const slug = getRestaurantSlugFromUrl(link.href);
            if (slug) {
                setRestaurant(slug);
                // If we are on the target page, scroll to the main content, otherwise a user won't see the result of a click.
                if (isSelfLink(link.href)) {
                    scrollToElement(getMainElement());
                }
            }
        });
    });
    window.addEventListener("hashchange", () => {
        const slug = getRestaurantSlugFromUrl();
        if (slug) {
            selectRestaurant(slug, false);
        }
    });
}
/**
 * Display the current restaurant as the first item in the restaurant list (PureScript implementation only).
 * TODO: Remove when PS implementation is deleted (in favor of `RestaurantSelection.tsx`).
 *
 * @param slug
 */
function sortCurrentRestaurantPS(slug) {
    // Apply code only for PS implementation.
    if (Skubacz.configuration.view_name === "restaurants" &&
        !document.getElementById("restaurant-selection")) {
        const parentElement = document.querySelector(".js-select-restaurant-all .js-select-restaurant-list");
        const element = parentElement === null || parentElement === void 0 ? void 0 : parentElement.querySelector(`[data-restaurant-slug="${slug}"]`);
        if (element) {
            parentElement === null || parentElement === void 0 ? void 0 : parentElement.insertBefore(element, parentElement === null || parentElement === void 0 ? void 0 : parentElement.firstChild);
        }
    }
}
/**
 * Initialize current restaurant and display relevant info.
 *
 * `Skubacz.configuration.restaurant_slug` contains restaurant slug of the current restaurant if it's single restaurant page (e.g. menu page).
 * The intention is to display restaurant data the user is most likely interested in.
 * For example, if user gets to the specific menu page from search engine, Facebook, navigation, etc., we want to save it and display the most relevant info on other pages without the need to select specific restaurant.
 *
 * TODO: Consider supporting multiple params in URL hash (home page sections or menu groups), e.g. `venezia.skubacz.test/#/r=restaurant-2#section-menu` or `venezia.skubacz.test/restaurant-2/sub-page#anchor`.
 */
function init() {
    const slug = getRestaurantSlugFromUrl() ||
        getRestaurantSlugFromMenuPageUrl() ||
        getCurrentRestaurantSlug() ||
        Skubacz.configuration.restaurant_slug;
    if (slug) {
        if (isMultiRestaurantPage &&
            !window.location.hash &&
            document.querySelector("[data-restaurant-visible='" + slug + "']")) {
            // Add restaurant slug to the URL without affecting browser history.
            history.replaceState({}, "", "#" + hashSlugPrefix + slug);
        }
        selectRestaurant(slug, false);
        sortCurrentRestaurantPS(slug);
    }
    attachEvents();
}
export default {
    init,
    selectRestaurant,
    getCurrentRestaurantSlug,
};
