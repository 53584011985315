import { RArray, RMap } from "../collections";
import { cartesianProduct, makeImmutable, toPairs } from "../core";
import { CustomParameterSet, MenuSection, MenuSectionId, MenuSectionItemId, ProductInstanceVariant, } from "../ordering";
export class MenuSectionParser {
    constructor(params) {
        this.productTypes = params.productTypes;
        this.customParameterTypes = params.customParameterTypes;
        makeImmutable(this);
    }
    parseSectionParameterChoices(customParameterValues) {
        return RMap.fromIterable(toPairs(customParameterValues).mapOptional(([customParameterTypeId, customParameterChoiceId]) => {
            const customParameterType = this.customParameterTypes.rawFind(customParameterTypeId);
            if (customParameterType === null) {
                return null;
            }
            const customParameterChoice = customParameterType.choices.rawFind(customParameterChoiceId);
            if (customParameterChoice === null ||
                customParameterChoice.availabilitySpec.type ===
                    "TemporarilyUnavailable") {
                return null;
            }
            return [customParameterType, customParameterChoice.id];
        }));
    }
    parse(id, section) {
        if (section.content.tag !== "Products") {
            return null;
        }
        const sectionParameterChoices = this.parseSectionParameterChoices(section.customParameterValues);
        const contentItems = new RArray(section.content.items);
        const productItems = contentItems.mapOptional(([, item]) => item.tag === "SingleProduct"
            ? this.productTypes.rawFind(item.itemProduct)
            : null);
        const creatorItems = RMap.fromIterable(contentItems.mapOptional(([sectionItemId, item]) => {
            if (item.tag !== "ProductCreator") {
                return null;
            }
            const creatorProducts = new RArray(item.creatorProducts).mapOptional((creatorProduct) => this.productTypes.rawFind(creatorProduct));
            if (creatorProducts.isEmpty) {
                return null;
            }
            return [new MenuSectionItemId(sectionItemId), creatorProducts];
        }));
        return new MenuSection({
            id: new MenuSectionId(id),
            variants: this.parseVariants(section, sectionParameterChoices, productItems),
            columns: this.parseColumns(section, sectionParameterChoices, productItems),
            creators: this.parseCreators(sectionParameterChoices, creatorItems),
        });
    }
    parseVariants(section, sectionParameterChoices, productItems) {
        const variantsParameterChoices = this.parseVariantCustomParameters(sectionParameterChoices, section.dropdownParameters);
        return RMap.fromIterable(productItems.map((productType) => {
            return [
                productType.id,
                variantsParameterChoices.map((variantParameterChoices) => new ProductInstanceVariant({
                    productTypes: { type: "Single", productType },
                    customParameterChoices: CustomParameterSet.fromChoices(variantParameterChoices.union(sectionParameterChoices)),
                })),
            ];
        }));
    }
    parseColumns(section, sectionParameterChoices, productItems) {
        if (section.columnParameter === null) {
            return RMap.empty();
        }
        const columnParameter = this.customParameterTypes.rawFind(section.columnParameter);
        if (columnParameter === null) {
            return RMap.empty();
        }
        return RMap.fromIterable(productItems.map((productType) => [
            productType.id,
            this.parseColumn(columnParameter).map((columnParameterChoices) => new ProductInstanceVariant({
                productTypes: { type: "Single", productType },
                customParameterChoices: CustomParameterSet.fromChoices(columnParameterChoices.union(sectionParameterChoices)),
            })),
        ]));
    }
    parseVariantCustomParameters(sectionParameterChoices, dropdownParameterIds) {
        const dropdownCustomParameters = new RArray(dropdownParameterIds).mapOptional((dropdownParameterId) => {
            const dropdownParameter = this.customParameterTypes.rawGet(dropdownParameterId);
            if (sectionParameterChoices.includes(dropdownParameter)) {
                // NOTICE Exclude parameter that has fixed choice inside of the section
                return null;
            }
            return dropdownParameter;
        });
        const dropdownParametersPair = dropdownCustomParameters.map((parameter) => [parameter, new RArray(parameter.choiceIds)]);
        const parameterCombinations = cartesianProduct(dropdownParametersPair);
        return parameterCombinations.map((combination) => RMap.fromIterable(combination.entries()));
    }
    parseColumn(columnParameter) {
        return RMap.fromIterable(columnParameter.choiceIds
            .rawValues()
            .map((customParameterChoiceId) => [
            customParameterChoiceId,
            RMap.singleton(columnParameter, customParameterChoiceId),
        ]));
    }
    parseCreators(sectionParameterChoices, creatorItems) {
        const customParameterChoices = CustomParameterSet.fromChoices(sectionParameterChoices);
        return creatorItems.map((creatorProductTypes) => new ProductInstanceVariant({
            productTypes: creatorProductTypes.size === 1
                ? { type: "Single", productType: creatorProductTypes.get(0) }
                : {
                    type: "Halves",
                    first: creatorProductTypes.get(0),
                    second: creatorProductTypes.get(1),
                },
            customParameterChoices,
        }));
    }
}
