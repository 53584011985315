import { makeAutoObservable } from "mobx";
import { ParameterScope } from "../ParameterScope";
import { ProductInstance } from "../ProductInstance";
import { ProductInstancePart } from "../ProductInstancePart";
import { HalvesCreatorState } from "./HalvesCreatorState";
import { ProductInstanceCreatorState } from "./ProductInstanceCreatorState";
import { SinglePartCreatorState } from "./SinglePartCreatorState";
/*
 * Utility class for building correct `ProductInstance` - it deals with changing productTypes in halves / splitting / removal of halves
 */
export class ProductInstanceCreator {
    constructor(params) {
        this.parentScope = ParameterScope.Uninitialized;
        this.possibleProductTypeIds = params.possibleProductTypeIds;
        this._state = params.state;
        this.trackingId = params.trackingId;
        makeAutoObservable(this);
    }
    static create({ productCategories, productTypes, productInstance, }) {
        const productCategory = productCategories.get(productInstance.productCategoryId);
        const possibleProductTypeIds = productTypes.filter((productType) => productType.productCategoryId.eq(productCategory.id)).ids;
        return new ProductInstanceCreator({
            possibleProductTypeIds,
            state: ProductInstanceCreatorState.fromProductInstanceParts({
                productCategory,
                commonConfig: productInstance.commonConfig,
                parts: productInstance.parts,
            }),
            trackingId: productInstance.trackingId,
        });
    }
    initWithScope(parentScope) {
        this.parentScope = parentScope;
        this.state.initWithScope(this.parentScope);
        this.selectInferredDefaults();
    }
    get state() {
        return this._state;
    }
    get productTypeIds() {
        return this._state.productTypeIds;
    }
    setState(newState) {
        this._state = newState;
        this.state.initWithScope(this.parentScope);
        this.state.selectInferredDefaults();
    }
    get isSplitPossible() {
        return this.state.isSplitPossible;
    }
    selectInferredDefaults() {
        this.state.selectInferredDefaults();
    }
    get instance() {
        return new ProductInstance({
            commonConfig: this.state.commonProductConfig,
            parts: this.state.productInstanceParts,
            trackingId: this.trackingId,
        });
    }
    split() {
        if (!ProductInstanceCreatorState.isSplitPossible(this.state)) {
            throw new Error("Split is not possible");
        }
        const { partCreator: firstHalf, productCategory } = this.state;
        const secondHalf = firstHalf.clone();
        this.setState(new HalvesCreatorState({
            productCategory,
            commonConfigCreator: this.state.commonConfigCreator,
            firstHalf,
            secondHalf,
        }));
    }
    removeHalf(half) {
        if (!ProductInstanceCreatorState.isSplit(this.state)) {
            throw new Error("Removing part is not possible at this state");
        }
        const partCreator = this.state[invertHalf(half)];
        this.setState(SinglePartCreatorState.create({
            productCategory: this.state.productCategory,
            initialCommonConfig: this.state.commonProductConfig,
            part: partCreator.productInstancePart,
        }));
    }
    removeFirstHalf() {
        this.removeHalf("firstHalf");
    }
    removeSecondHalf() {
        this.removeHalf("secondHalf");
    }
    setPartProductType(productType) {
        if (ProductInstanceCreatorState.isSplit(this.state)) {
            throw new Error("Use setFirstHalfProductType/setSecondHalfProductType in split state");
        }
        this.setState(SinglePartCreatorState.create({
            productCategory: this.state.productCategory,
            initialCommonConfig: this.state.commonProductConfig,
            part: ProductInstancePart.empty(productType),
        }));
    }
    setFirstHalfProductType(productType) {
        if (!ProductInstanceCreatorState.isSplit(this.state)) {
            throw new Error("Use setPartProductType in non-split state");
        }
        this.setState(HalvesCreatorState.create({
            productCategory: this.state.productCategory,
            initialCommonConfig: this.state.commonProductConfig,
            firstHalf: ProductInstancePart.empty(productType),
            secondHalf: this.state.secondHalf.productInstancePart,
        }));
    }
    setSecondHalfProductType(productType) {
        if (!ProductInstanceCreatorState.isSplit(this.state)) {
            throw new Error("Use setPartProductType in non-split state");
        }
        this.setState(HalvesCreatorState.create({
            productCategory: this.state.productCategory,
            initialCommonConfig: this.state.commonProductConfig,
            firstHalf: this.state.firstHalf.productInstancePart,
            secondHalf: ProductInstancePart.empty(productType),
        }));
    }
    /**
     * Try to fix availability in places where it can be done automatically.
     *
     * Currently this amounts to deselecting unavailable modifier items.
     *
     * This function is idempotent, and can be used in a MobX Reaction to automatically fix such problems as soon as they arise. For example:
     *
     * ```
     * autorun(() => creator.fixAvailability());
     * ```
     */
    fixAvailability() {
        this._state.fixAvailability();
    }
}
function invertHalf(half) {
    switch (half) {
        case "firstHalf":
            return "secondHalf";
        case "secondHalf":
            return "firstHalf";
    }
}
