export class OrderingAvailabilityService {
    constructor(params) {
        this.rpcService = params.rpcService;
    }
    async getOrderingAvailability(params) {
        const response = await this.rpcService.rpc("GetOrderingAvailabilityRequest", {
            restaurantId: params.restaurantId.value,
            isQrWaiterOrder: params.isQrWaiterOrder,
            // TODO: implement review mode with new client for iOS
            isIosReviewMode: false,
        });
        if (response === null) {
            throw new Error("Unable to load ordering availability");
        }
        return response;
    }
}
