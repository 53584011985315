import { IdMap, RArray, RMap } from "../collections";
import { makeImmutable, toPairs } from "../core";
import { CrossSellingItemType, CrossSellingItemTypeId, CrossSellingList, CrossSellingListId, CrossSellingListRepository, } from "../ordering";
export class CrossSellingListParser {
    constructor(params) {
        this.catalogue = params.catalogue;
        this.productTypeIds = params.productTypeIds;
        this.productInstanceParser = params.productInstanceParser;
        makeImmutable(this);
    }
    parseCrossSellingLists() {
        const crossSellingListIds = toPairs(this.catalogue.crossSellingListsV2).mapOptional(([rawCrossSellingListId, crossSellingList]) => {
            // TODO Filter visibility of lists
            return new CrossSellingList({
                id: new CrossSellingListId(rawCrossSellingListId),
                items: new RArray(crossSellingList.items).mapOptional(([crossSellingItemTypeId, crossSellingListItem]) => {
                    const productInstance = this.productInstanceParser.parseCartProductKey(crossSellingListItem.content);
                    if (productInstance === null) {
                        return null;
                    }
                    return new CrossSellingItemType({
                        id: new CrossSellingItemTypeId(crossSellingItemTypeId),
                        kind: "Manual",
                        productInstance,
                    });
                }),
            });
        });
        return IdMap.fromIterable(crossSellingListIds);
    }
    parseCrossSellingListAssociations(crossSellingListIds) {
        return RMap.fromIterable(new RArray(this.productTypeIds).mapOptional((productTypeId) => {
            const productType = this.catalogue.products[productTypeId.value];
            let crossSellingListId = null;
            if (productType.crossSellingListOverride !== null) {
                crossSellingListId = crossSellingListIds.rawFind(productType.crossSellingListOverride);
            }
            if (crossSellingListId === null) {
                const productCategory = this.catalogue.categories[productType.category];
                if (productCategory.defaultCrossSellingList !== null) {
                    crossSellingListId = crossSellingListIds.rawFind(productCategory.defaultCrossSellingList);
                }
            }
            if (crossSellingListId === null) {
                return null;
            }
            return [productTypeId, crossSellingListId];
        }));
    }
    parse() {
        const crossSellingLists = this.parseCrossSellingLists();
        const crossSellingListAssociations = this.parseCrossSellingListAssociations(crossSellingLists.ids);
        return new CrossSellingListRepository({
            crossSellingLists,
            crossSellingListAssociations,
        });
    }
}
